<template>
  <section id="CompagniaPage">
    <v-container fluid :class="containerPadding">
      <table-component
        fixed-header
        id="compagnia_elenco"
        :resource="tableDataset"
        config="compagnia-table"
        toolbar-title="Compagnie"
        :loading="loading"
        loading-text="attendi un attimo"
        @on-edit="onEdit($event)"
        @on-delete="onDelete($event)"
        @on-add-new-item="onAddNewItem($event)"
        :items-per-page="itemsPerPage"
        >
      </table-component>
    </v-container>
  </section>
</template>
<script>
  import TableComponent from "../ui/TableComponent.vue";
  export default {
    name: "compagnia-page",
    components: {
      TableComponent,
    },
    data() {
      return {
        /** @var selectedItems elementi selezionati della tabella passati tramite un evento */
        selectedItems: [],
      };
    },
    mounted() {
      this.$store.dispatch("ACTION_GET_COMPAGNIE");
    },
    methods: {
      onEdit(itemToEdit) {
      
        console.log("onEdit - CompagniaPage", JSON.stringify(itemToEdit.id, null, 2))
        // TODO | VUEX | ROUTER | setModificaPagina
        this.$router.push("/modifica/compagnia/" + itemToEdit.id,);
      },
      onDelete(compagnia){
        alert("TODO: ACTION_DELETE_COMPAGNIA cancellare a cascata ");
        compagnia
        // this.$store.dispatch("ACTION_DELETE_COMPAGNIA",compagnia)
        
      },
      onAddNewItem() {
        this.$router.push("/new/compagnia/");
      },
    },
    computed: {
      tableDataset() {
        return this.$store.state.compagnie;
      },
      loading() {
        return this.$store.state.loading;
      },
    },
  };
</script>
