import Vue from "vue";
import vuetify from "./plugins/vuetify";

import VueRouter from "vue-router";
import { routes } from "./routes";
import { sync } from "vuex-router-sync";
import { store } from "./store/store.js";

import "./filters/NumbersFilters";

import App from "./App.vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue2-datepicker/index.css";
import "./app.css";



Vue.prototype.can = function can(value) {


    // let permissions = store.state.permissions;
    let permissions = JSON.parse(window.sessionStorage.getItem("permissions"));
    // console.log("A",window.sessionStorage.getItem("permissions"));
    let _return = false;


    if (!Array.isArray(permissions)) {
        return false;
    }

    if (value.includes("|")) {
        value.split("|").forEach(function (item) {
            if (permissions.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes("&")) {
        _return = true;
        value.split("&").forEach(function (item) {
            if (!permissions.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        // console.log("C",permissions.includes(value.trim()));

        _return = permissions.includes(value.trim());
    }
    return _return;
};

Vue.prototype.is = function is(value) {
    // if (window.vueSpatiePermissions == undefined) {
    //     return false;
    // }

    // let roles = store.state.roles;
    let roles = JSON.parse(window.sessionStorage.getItem("roles"));

    let _return = false;

    if (!Array.isArray(roles)) {
        return false;
    }

    if (value.includes("|")) {
        value.split("|").forEach(function (item) {
            if (roles.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes("&")) {
        _return = true;
        value.split("&").forEach(function (item) {
            if (!roles.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = roles.includes(value.trim());
    }

    return _return;
};





Vue.prototype.itemsPerPage = -1;

Vue.config.productionTip = false;
Vue.use(VueRouter);

Vue.mixin({
    data: function () {
        return {
            get containerPadding() {
                return "px-9 py-0";
            },
            get primary() {
                return "indigo";
            },
            get smallTab() {
                return "5rem";
            },
        };
    },
});

export const router = new VueRouter({
    mode: "history",
    // history: createWebHashHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    // Imposta il titolo della pagina in base alla meta-informazione della rotta
    document.title = to?.meta?.title || 'Teo';
    next();
});


// DOCS | ROUTER https://youtu.be/7ZDdedrPJx0?t=1039
// // https://github.com/vuejs/vuex-router-sync
sync(store, router); // done. Returns an unsync callback fn

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!window.sessionStorage.getItem("token") || window.sessionStorage.getItem("token") == 'undefined' ) {
            console.log(
                "router.beforeEach",
                to.matched,
                window.sessionStorage.getItem("vueSpatiePermissions")
            );
            next({
                path: "/login"
                // ,
                // query: {
                //     redirectedFrom: to.fullPath,
                // },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");

// unsync()
