<template>
  <section v-if="persona_fisica" class="PersonaFisicaForm">
    <v-row class="pt-0 px-0">
      <!-- nome -->
      <v-col cols="12" class="p-0">
        <v-text-field
          id="nome"
          data-cy="persona-fisica-nome"
          v-model="persona_fisica.nome"
          type="text"
          :error-messages="errors.nome"
          :error="errors.nome && errors.nome.length > 0"
          @input="$emit('input', persona_fisica)"
          label="Nome">
        </v-text-field>
      </v-col>

      <!-- cognome -->
      <v-col cols="12" class="p-0">
        <v-text-field
          id="cognome"
          label="Cognome"
          data-cy="persona-fisica-cognome"
          v-model="persona_fisica.cognome"
          type="text"
          :error-messages="errors.cognome"
          :error="errors.cognome && errors.cognome.length > 0"
          @input="$emit('input', persona_fisica)">
        </v-text-field>
      </v-col>

      <!-- sesso -->
      <!-- <v-col cols="12" class="p-0">
        <v-text-field
          id="sesso"
          data-cy="persona-fisica-sesso"
          v-model="persona_fisica.sesso"
          type="text"
          :error-messages="errors.sesso"
          :error="errors.sesso && errors.sesso.length > 0"
          label="Sesso"
          @input="$emit('input', persona_fisica)"></v-text-field>
      </v-col> -->

      <!-- comuneNascita -->
      <v-col cols="12" sm="10" class="p-0">
        <v-text-field
          id="comuneNascita"
          data-cy="persona-fisica-comuneNascita"
          v-model="persona_fisica.comuneNascita"
          type="text"
          :error-messages="errors.comuneNascita"
          :error="errors.comuneNascita && errors.comuneNascita.length > 0"
          label="Comune di Nascita"
          @input="$emit('input', persona_fisica)"></v-text-field>
      </v-col>

      <!-- Comune nascita Field -->

      <!-- provinciaNascita -->
      <v-col cols="12" sm="2" class="p-0">
        <v-text-field
          id="provinciaNascita"
          data-cy="persona-fisica-provinciaNascita"
          v-model="persona_fisica.provinciaNascita"
          type="text"
          :error-messages="errors.provinciaNascita"
          :error="errors.provinciaNascita && errors.provinciaNascita.length > 0"
          label="Provincia"
          @input="$emit('input', persona_fisica)"></v-text-field>
      </v-col>

      <!-- nazioneNascita -->
      <v-col cols="12" class="p-0">
        <v-text-field
          id="nazioneNascita"
          data-cy="persona-fisica-nazioneNascita"
          v-model="persona_fisica.nazioneNascita"
          type="text"
          :error-messages="errors.nazioneNascita"
          :error="errors.nazioneNascita && errors.nazioneNascita.length > 0"
          label="Nazione"
          @input="$emit('input', persona_fisica)">
        </v-text-field>
      </v-col>

      <v-col cols="12" class="p-0">
        <teo-date-picker
          data-cy="persona-fisica-dataNascita"
          label="Data di Nascita"
          v-model="persona_fisica.dataNascita"
          value-type="YYYY-MM-DD"
          format="DD/MM/YYYY"
          @input="$emit('input', persona_fisica)" />
      </v-col>

      <!-- cf -->
      <v-col cols="12" class="p-0">
        <v-text-field
          id="cf"
          data-cy="persona-fisica-cf"
          v-model="persona_fisica.cf"
          type="text"
          :error-messages="errors.cf"
          :error="errors.cf && errors.cf.length > 0"
          label="Codice fiscale"
          @input="$emit('input', persona_fisica)"></v-text-field>
      </v-col>
      <v-col cols="12" class="p-0">
        <v-text-field
          id="cf"
          data-cy="persona-fisica-iscrizioneRUI"
          v-model="persona_fisica.iscrizioneRUI"
          type="text"
          :error-messages="errors.iscrizioneRUI" 
          :error="errors.iscrizioneRUI && errors.iscrizioneRUI.length > 0"
          label="iscrizione RUI"
          @input="$emit('input', persona_fisica)"></v-text-field>
      </v-col>
      
      <v-col cols="12" class="p-0">
        <teo-date-picker
          data-cy="persona-fisica-iscrizioneRUIData"
          label="Data Iscrizione RUI"
          v-model="persona_fisica.iscrizioneRUIData"
          value-type="YYYY-MM-DD"
          format="DD/MM/YYYY"
          @input="$emit('input', persona_fisica)" />
      </v-col>
      <v-col cols="12" class="p-0">
        <v-text-field
          id="qualifica"
          data-cy="persona-fisica-qualifica"
          v-model="persona_fisica.qualifica"
          type="text"
          :error-messages="errors.qualifica" 
          :error="errors.qualifica && errors.qualifica.length > 0"
          label="Qualifica"
          @input="$emit('input', persona_fisica)"></v-text-field>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import teoDatePicker from "../ui/form/teo-date-picker.vue";
  // import AutocompleteVue from "../ui/Autocomplete.vue";

  export default {
    components: { teoDatePicker },
    props: ["value"],
    data() {
      return {
        persona_fisica: this.value ?? {},
        errors: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
