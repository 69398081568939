import axios from 'axios';

// storageModule.js

export function saveToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

// Funzione per leggere un oggetto JSON dal localStorage
export function readFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    if (data == "undefined") {
        return {}
    }
    return data ? JSON.parse(data) : null;
}


// Funzione per impostare una chiave nel remote storage
export async function saveToRemoteStorage(key, value) {
    try {
        const response = await axios.post(process.env.VUE_APP_API_URL + `/api/remote-storage/${key}`, {
            key,
            value: JSON.stringify(value) // Converte il valore in formato JSON
        });
        return response.data;
    } catch (error) {
        console.error('Errore durante la creazione o l\'aggiornamento:', error);
        throw error;
    }
}

// Funzione per ottenere i dati da una chiave nel remote storage
export async function readFromRemoteStorage(key) {
    try {
        const response = await axios.get(process.env.VUE_APP_API_URL + `/api/remote-storage/${key}`);
        return response.data;
    } catch (error) {
        console.error('Errore durante la lettura dei dati:', error);
        throw error;
    }
}

// Funzione per eliminare una chiave dal remote storage
export async function deleteFromLocalStorage(key) {
    try {
        const response = await axios.delete(process.env.VUE_APP_API_URL + `/api/remote-storage/${key}`);
        return response.data;
    } catch (error) {
        console.error('Errore durante l\'eliminazione:', error);
        throw error;
    }
}


