<template>
  <section v-if="compagnia_contrattuale" class="compagnia_contrattuale">
    <v-row class="pt-0 d-flex align-end">
      <!-- attivo -->
      
      <v-col style="display: none;" cols="12" sm="12" class="m-0 p-0">
       
          <v-checkbox
          label="Attiva"
          id="attivo"
          
          v-model="compagnia_contrattuale.attivo"
          dense
          data-cy="attivo"
          color="primary"
          
          :error-messages="errors.attivo"
          :error="errors.attivo && errors.attivo.length > 0">
        </v-checkbox>
      </v-col> 
   
      
      <!-- nome -->
      <v-col cols="12" sm="12" class="m-0 p-0">
        <v-text-field
          id="nome"
          label="Nome"
          data-cy="nome"
          v-model="compagnia_contrattuale.nome"
          dense
          
          type="text"
          :error-messages="errors.nome"
          :error="errors.nome && errors.nome.length > 0">
        </v-text-field>
      </v-col>

      <!-- visibile_allegato_4 -->
      <v-col cols="12" sm="12" class="m-0 p-0">
        <!-- #error-messages -->
        <v-checkbox
          label="Visibile Allegato 4"
          id="visibile_allegato_4"
          
          v-model="compagnia_contrattuale.visibile_allegato_4"
          dense
          data-cy="visibile_allegato_4"
          color="primary"
          
          :error-messages="errors.visibile_allegato_4"
          :error="
            errors.visibile_allegato_4 && errors.visibile_allegato_4.length > 0
          ">
          </v-checkbox>
      </v-col>

      <!-- esiste_ratifica -->
      <v-col cols="12" sm="12" class="m-0 p-0">
        <!-- #error-messages -->
        <v-checkbox
          label="Esiste Ratifica"
          id="esiste_ratifica"
          
          v-model="compagnia_contrattuale.esiste_ratifica"
          dense
          data-cy="esiste_ratifica"
          color="primary"
          
          :error-messages="errors.esiste_ratifica"
          :error="
            errors.esiste_ratifica && errors.esiste_ratifica.length > 0
          "></v-checkbox>
      </v-col>

      <!-- associato_preventivatore_rca -->
      <v-col cols="12" sm="12" class="m-0 p-0">
        <!-- #error-messages -->
        <v-checkbox
          label="Associato Preventivatore Rca"
          id="associato_preventivatore_rca"
          
          v-model="compagnia_contrattuale.associato_preventivatore_rca"
          dense
          data-cy="associato_preventivatore_rca"
          color="primary"
          
          :error-messages="errors.associato_preventivatore_rca"
          :error="
            errors.associato_preventivatore_rca &&
            errors.associato_preventivatore_rca.length > 0
          "></v-checkbox>
      </v-col>

      <!-- effettua_versamenti_netto_provvigioni -->
      <v-col cols="12" sm="12" class="m-0 p-0">
        <!-- #error-messages -->
        <v-checkbox
          label="Effettua Versamenti Netto Provvigioni"
          id="effettua_versamenti_netto_provvigioni"
          
          v-model="compagnia_contrattuale.effettua_versamenti_netto_provvigioni"
          dense
          data-cy="effettua_versamenti_netto_provvigioni"
          color="primary"
          :error-messages="errors.effettua_versamenti_netto_provvigioni"
          :error="
            errors.effettua_versamenti_netto_provvigioni &&
            errors.effettua_versamenti_netto_provvigioni.length > 0
          "></v-checkbox>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    props: ["value"],
    data() {
      return {
        compagnia_contrattuale: this.value,
      };
    },
    computed:{
      // TODO: provare a mettere gli errori all' esterno creando un componente super stupido
      ...mapState('CompagniaContrattualeModule',['errors'])
      // errors:
    },
    watch:{
      compagnia_contrattuale(newValue){
        
        this.$emit('input', newValue)
      }
    }
  };
</script>

<style lang="scss" scoped></style>
