<template>
 
<v-alert outlined
         type="error"
         class="ma-3"
         border="left"
     
>
<h3>Nuova tariffa GOOld</h3>
Gentili collaboratori vi informiamo che dal 26/5 sarà applicata la 
<strong>nuova tariffa GOOld.</strong> <br>
Saranno aggiornate le quotazioni in tutti gli stati. <br>
<strong>Teo sarà offline dalle ore 19 del 24/05/2024 alle 11 del 27/05/2024.</strong>

</v-alert>

 
</template>
  
<script>



export default {
  data: () => ({
    drawer: null
  }),
  components: {  },
  mounted(){
    this.$store.state.compagnie
  },
  computed: {
      cards() {
        return this.$store.state.dashboard.cards;
      },
      breadcrumbs() {
        return this.$store.state.breadcrumbs;
      },
    },
}
</script>