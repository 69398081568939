import Vue from 'vue'

// DOCS: filter | https://stackoverflow.com/questions/45053964/in-vue-js-how-do-i-write-custom-filters-in-separate-file-and-use-them-in-various
Vue.filter('currency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
    });
    return formatter.format(value);
  });
  