<template>
    <v-dialog max-width="600px" data-cy="addDocumentDialog" v-model="addDocumentDialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn small color="primary" dark class="mb-2 mr-2 ml-auto" v-bind="attrs" v-on="on">
                Aggiungi
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="text-h5">Aggiungi Documento</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>

                        <div class="form-group py-0 px-1 mb-3">
                            <label for="nome_file">Nome file:</label>
                            <input class="form-control-sm form-control" autocomplete="new-password" type="nome_file"
                                data-cy="nome_file" v-model="nome_file" name="nome_file" />

                        </div>
                        <div class="form-group py-0 px-1 mb-3">
                            <v-file-input label="file" v-model="file" accept="image/*,.pdf"></v-file-input>
                        </div>
                    </v-container>
                </v-form>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="blue darken-1" text @click="addDocumentDialog = false">Annulla</v-btn>
                <v-btn small color="blue darken-1" text @click="addDocument">Salva</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
export default {

    data() {
        return {
            nome_file: "",
            file: null,
            addDocumentDialog: false,
            errors: null,
        };
    },
    props: {
        collection: {
            type: String,
            required: true,
        },
        model: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        }
    },
    methods: {
        async addDocument() {
            var apiURL = process.env.VUE_APP_API_URL + '/api/quotazioni/upload';
            let formData = new FormData();
            formData.append("file", this.file);
            formData.append("nome_file", this.nome_file)
            formData.append("id", this.id);
            formData.append("collection", this.collection);
            formData.append("model", this.model);
            axios.post(apiURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    this.addDocumentDialog = false
                    this.$emit('success', response);

                })
                .catch((response) => {
                    this.addDocumentDialog = false
                    this.$emit('error', response);
                });
        },
    }
}
</script>
