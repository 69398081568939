<template>
  <section class="page" id="CollaboratorePage">
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card class="p-0">
          <!-- :resource="`/api/${model}`" -->
          <table-component
            :resource="tableDataset"
            fixed-header
            :config="`collaboratore-index-table`"
            toolbar-title="Collaboratori"
            :loading="loading"
            loading-text="attendi un attimo"
            @on-edit="onEdit($event)"
            @on-delete="onDelete($event)"
            @on-add-new-item="onAddNewItem($event)"
            :items-per-page="itemsPerPage"
            >
            <template v-slot:[`item.settore.rca`]="{ item }">
              <v-chip
                
                :color="getColorRCA(getProvvigioneValue(item,'RCA'))"
                dark
                label>
               {{ getProvvigioneValue(item,'RCA') }} %
              </v-chip>
             
            </template>

            <template v-slot:[`item.settore.danni`]="{ item }">
              <v-chip
                
                :color="getColorDanni(getProvvigioneValue(item,'DANNI'))"
                dark
                label>
                {{ getProvvigioneValue(item,'DANNI') }} %
              </v-chip>
        
            </template>
            <template v-slot:[`item.settore.vita`]="{ item }">
              <v-chip
               
                :color="getColorVita(getProvvigioneValue(item,'VITA'))"
                dark
                label>
                {{getProvvigioneValue(item,'VITA')}}%
              </v-chip>
    
            </template>
          </table-component>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card> tab 3 </v-card>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
  // import App from '../../App.vue';
  // import { getColorVita,getColorRCA,getColorDanni } from '@/demo';

  import TableComponent from "../ui/TableComponent.vue";
  // import {getColorVita,getColorRCA,getColorDanni } from '../../store.js'
  // import { mapGetters } from 'vuex';
  const Collaboratore = {};
  // const model = 'collaboratore'

  export function getColorRCA(percent) {
    console.log(percent);
    // const style = 'lighten-5'
    if (percent >= 70) return "green";
    else if (percent < 70 && percent >= 30) return "orange lighten-1";
    else return "red";
  }

  export function getColorDanni(percent) {
    if (percent >= 25) return "green";
    else if (percent < 25 && percent >= 10) return "orange lighten-1";
    else return "red";
  }

  export function getColorVita(percent) {
    if (percent >= 5) return "green";
    else if (percent < 5 && percent >= 2) return "orange lighten-1";
    else return "red";
  }

  export function getProvvigioneValue(item,key){
  
    if(item.provvigioni_settori.length == 0){
      return 0;
    }else{
      key
      const _item = item.provvigioni_settori.find(p => p.nome == key)
      return _item.perc_provvigione
    }
  }

  export default {
    name: "collaboratore-assicurativo-page",
    data() {
      return {
        model: "collaboratore",
        page: { toolbarTitle: "Collaboratori" },
        breadcrumbs: [],
        tab: 0,
        dialog: false,
        // TODO Controllare se tenerlo
        itemToEdit: Collaboratore,
        /** @var selectedItems elementi selezionati della tabella passati tramite un evento */
        selectedItems: [],
      };
    },
    mounted() {
      this.$store.dispatch("ACTION_GET_COLLABORATORI");
    },
    methods: {
      getColorVita(percent) {
        return getColorVita(percent);
      },
      getColorRCA(percent) {
        return getColorRCA(percent);
      },
      getColorDanni(percent) {
        return getColorDanni(percent);
      },
      getProvvigioneValue(item,key){
        return getProvvigioneValue(item,key)
      },

      onEdit(itemToEdit) {
        this.dialog = true;
        this.itemToEdit = itemToEdit;
        
        console.log(
          "onEdit - CollaboratorePage",
          JSON.stringify(itemToEdit.id, null, 2)
        );

        // http://localhost:8080/modifica/collaboratore/13

        // path: '/front/modifica/collaboratore/:id',
        this.$router.push("/modifica/collaboratore/" + itemToEdit.id);
        // this.$router.push({"name":"collaboratore_edit",param:{"id":itemToEdit.id}})
      },
      // TODO: rivedere action

      // onActionSelect(voceMenuActionSelezionata) {
      //     ActionDataService.execute(voceMenuActionSelezionata.action, this.selectedItems).then((res) => {
      //         console.log("response action: ", res);
      //     });
      // },
      /** ADD_NEW */
      onAddNewItem() {
        this.$router.push("/new/collaboratore/");
      },
      afterItemCreated() {
        //console.log('afterItemCreated')
        //window.location.href = "/compagnia";
      },
      onAnnulla() {
        this.dialog = false;
      },
    },
    components: {
      TableComponent,
    },
    computed: {
      tableDataset() {
        return this.$store.state.collaboratori;
      },
      loading() {
        return this.$store.state.loading;
      }
    },
  };
</script>
