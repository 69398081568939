<template lang="html">
  <section>
    <div v-if="state && state.slug && state.slug.includes('pronto_per_la_firma')">
      <v-list>
        <v-list-item three-line class="m-0 px-3 py-0">
          <v-list-item-content>
            <div
              class="text-overline mb-0 red--text"
              v-if="signature_type === null"
            >
              <v-icon color="red">mdi-alert-circle-outline</v-icon>
              Modalita di firma
            </div>
            <div class="text-overline mb-0" v-if="signature_type !== null">
              {{ signature_type.label }}
            </div>

            <v-radio-group v-model="signature_type">
              <v-radio
                v-for="option in sign_option"
                :key="option.slug"
                :label="option.label"
                :value="option"
              ></v-radio>
            </v-radio-group>

            <!-- MINI FORM E INVIO FIRMA DIGITALE ------------------------------------------------ -->
            <section
              id="digital_signature_recipient"
              v-if="signature_type?.slug == 'otp'"
            >
              <v-text-field
                v-if="false"
                v-model="signature_data.phoneNumber"
                label="Numero di telefono per autenticazione OTP"
                prepend-icon="mdi-phone"
                required
              ></v-text-field>

              <v-text-field
                v-if="false"
                v-model="signature_data.email"
                label="Email per invio dei documenti da firmare"
                prepend-icon="mdi-email"
                required
              ></v-text-field>

              <v-btn
                small
                dark
                color="primary"
                @click="$emit('on-invia-richiesta-di-firma')"
              >
                Invia richiesta di firma
              </v-btn>
            </section>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <v-alert v-if="errorMessage" class="p-2" dense text type="error">
      <h5 class="subtitle-1">{{ errorMessage.message }}</h5>

      <div class="subtitle-2" v-for="error in errorMessage.errors" :key="error">
        {{ error }}
      </div>
    </v-alert>


    <v-alert v-if="!(state.slug === 'nessuno_stato')" class="p-2" dense text :type="state.type || 'success'">
      <h5>{{ state.label }}</h5>

      <v-btn
        v-if="state.slug === 'firma_stato_bozza'"
        small
        dark
        color="primary"
        @click="$emit('on-invia-richiesta-di-firma')"
      >
        re-invia richiesta di firma
      </v-btn>
      <v-btn
        v-if="state.slug === 'firma_stato_bozza'"
        small
        dark
        color="primary"
        @click="$emit('on-invia-richiesta-di-firma')"
      >
        re-invia richiesta di firma
      </v-btn>

      <v-btn
        v-if="state.slug === 'firma_stato_bozza'"
        small
        dark
        color="primary"
        @click="$emit('on-update')"
      >
        salva modifiche
      </v-btn>
    </v-alert>
    <v-simple-table v-if="email && cell">
      <thead>
        <tr>
          <th scope="col">Email:</th>
          <th scope="col">Telefono:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ email }}</td>
          <td>{{ cell }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </section>
</template>

<script lang="js">
// import { Api, errorCallback } from '@/http_tools/axiosModule';

export default  {
   name: 'BoxFirma',
   props: {
    policyId:{
       type:Number
    },
    cell: {
       type:String
    },
    email: {
       type:String
    },
    media: {
      type:Array,
      default:() => []
    },
    state: {
      type: Object,
      // default: 'in_attesa_di_firma',
      //  validator: function(value) {
      //   return ['in_lavorazione','pronto_per_la_firma','in_attesa_di_firma','conclusa_con_successo', 'in_attesa_di_emissione'].indexOf(value) !== -1;
      // }
    },
    errorMessage:{
      type: [Object,Boolean],
      default: () => {}
    }
  },
    mounted () {

    },
    data () {
      return {
        signature_type:null,
        signature_data: {
          "phoneNumber":this.phoneNumber,
          "email":this.email
        },
        sign_option:[
          {
            'label':"Firma Digitale OTP",
            'slug': "otp"
          },
          {
            'label':"Firma Manuale",
            'slug': "manual"
          }
        ]
      }
    },
    methods: {}
}
</script>

<style scoped lang="scss"></style>
