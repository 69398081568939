<template>
  <div class="crud_table TableComponent">
    <v-data-table
      class="elevation-0"
      dense
      :show-select="true"
      item-key="id"

      v-bind="$attrs"
      v-on="$listeners"

      v-model="selected"
      :single-select="false"
      :sort-by="sort.by"
      :sort-desc="sort.desc"
      :headers="headers"
      :items="resource"
      :search="search"
     
      fixed-header
      loading-text="un secondo ..."
      @click:row="rowClick($event)"
      @input="selectionChange($event)"
      >


      <!-- TOOLBAR_TITLE AND DIALOG -->
      <template v-slot:top>
        <v-toolbar flat>
          <div id="toolbar_action__menu_sx" v-if="actionTableMenu">
            <v-btn data-cy="aggiungi" color="primary" @click="onAddNewItem()">
              Aggiungi
            </v-btn>
            <v-btn
              color="primary"
              size="x-small"
              v-for="link in actionTableMenu"
              :key="link.text"
              @click="onActionSelect(link)"
              text
              >
              {{ link.text }}
            </v-btn>
          </div>

          <v-spacer></v-spacer>

          <v-card-title>
            <!-- hide-details -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Cerca"
              single-line></v-text-field>
          </v-card-title>

        
          <action-menu
            v-if="actionTableMenu"
            class="action_menu"
            action-disabled="true"
            sub-header="I documenti selezionati:"
            :actions="actionTableMenu" />
        </v-toolbar>
      </template>

      <!-- ACTION_TOOLBAR ROW -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          data-cy="action-ico-edit"
          class="mr-2"
          @click="editItemHandler(item)">
          mdi-pencil
        </v-icon>
        <v-icon
          small
          data-cy="action-ico-delete"
          @click.stop="deleteRow(item)">
          mdi-delete
        </v-icon>
      </template>

      <!-- SLOT EREDITATI -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-data-table>
    <div
      v-if="items && items.length === 0"
      class="d-flex align-content-center justify-content-center">
      nessun elemento trovato
    </div>
  </div>
</template>

<script>
  import ActionMenu from "./ActionMenu.vue";
  export default {
    props: {
      config: String,
      resource: [String, Array, Object],
      toolbarTitle: String,
      name: String,
    },
    data() {
      return {
        loading: false,
        test: 2,
        search: "",
        // items: this.resource,
        items: [],
        headers: [],
        sort: {},
        editedIndex: -1,
        editedItem: null,
        selected: [],
        actionTableMenu: false,
      };
    },
    computed: {
      formTitle() {
        // NOTE: -1 crea un item
        return this.editedIndex === -1 ? "Nuovo" : "Modifica";
      },
      // resourceComputed(){
      //     return
      // },
      itemToEdit() {
        return this.editedItem;
      },
    },
    mounted() {
      // console.log("table-component",this.config)
      this.loadSettings();
    },
    methods: {
      onActionSelect(item) {
        console.log("on-action-select", item);
      },
      editItemHandler(item) {
        
        this.$emit("on-edit", item);
      },
      onAddNewItem() {
        this.$emit("on-add-new-item");
      },
      selectionChange() {
        this.$emit("on-selection-change", this.editedItem);
      },
      deleteRow(item) {

        let text = "Conferma l'eliminazione";
        if (confirm(text) == true) {
          this.$emit("on-delete", item);
        }

      },
      rowClick(evt) {
        console.log("rowClick", evt);
        this.editItemHandler(evt);
      },
      async loadSettings() {
        const _setting = await fetch("/headers/" + this.config + ".json?t="+Date.now());
        const setting = await _setting.json();
        this.headers = setting.headers;
        this.multi = setting.sort.multi;
        this.by = setting.sort.by;
        this.desc = setting.sort.desc;
        this.multiSelect = setting.multiSelect;
        this.actionTableMenu = setting.actionTableMenu;
      },
    },
    components: { ActionMenu },
  };
</script>
<!-- 
    DOCS | https://codepen.io/Jayesh_v/pen/wvgjMva?editors=1000 
    DOCS | https://www.vinaysahni.com/best-practices-for-a-pragmatic-restful-api
    DOCS | https://stackoverflow.com/questions/62511252/vuetify-data-table-combine-dynamic-slots-with-row-item-slots    
-->
<style>
  td.text-start,
  th.text-start,
  th.text-start.sortable {
    padding-left: 1.5rem !important;
  }

  th.text-start:first-child,
  td.text-start:first-child {
    padding-left: 0rem !important;
    /* background:#fafafa!important;*/
  }

  .v-chip.v-size--default {
    border-radius: 16px;
    font-size: 14px;
    height: 23px;
  }

  .v-chip {
    padding: 0 5px;
  }
</style>
