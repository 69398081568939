/**
  DOCS VUE | ROUTER | https://v3.router.vuejs.org/guide/essentials/named-routes.html#named-routes
 */

export const routes = [
    {
        path: "/ciccio",
        component: require("./NoAuthBase.vue").default,
        name: "no-auth-base",
        children: [
            // DASHBOARD
            {
                path: "/quotazione/cliente/:uuid",
                component: require("./components/page/QuotazioneClientePage")
                    .default,
                name: "FormCliente",
            },
        ],
    },
    {
        path: "/",
        component: require("./LayoutBase.vue").default,
        name: "layout-base",
        meta: { requiresAuth: true },
        children: [
            // DASHBOARD
            {
                path: "/",
                // ./components/page/HomeDashboardPage.vue
                component: require("./components/page/HomeDashboardPage.vue")
                    .default,
                name: "dashboard",
                meta: {
                    requiresAuth: true,
                    title: "Dashboard",
                    icon: "mdi-view-dashboard",
                    // Generazione automatica interfaccia
                    showIn: ["SidebarNav"],
                },
            },
            {
                path: "/titoli",
                // ./components/page/HomeDashboardPage.vue
                component: require("./components/page/TitoloPage.vue").default,
                name: "titoli",
                meta: {
                    requiresAuth: true,
                    title: "Titoli",
                    icon: "mdi-view-agenda",
                    // Generazione automatica interfaccia
                    showIn: ["SidebarNav"],
                },
            },
            {
                path: "/dashboard",
                // ./components/page/HomeDashboardPage.vue
                component: require("./components/page/HomeDashboardPage.vue")
                    .default,
                name: "dashboard",
                meta: {
                    requiresAuth: true,
                    title: "Dashboard",
                    icon: "mdi-view-dashboard",
                    // Generazione automatica interfaccia
                    showIn: ["SidebarNav"],
                },
            },

            // POLIZZA
            {
                path: "/polizza",
                component: require("./components/polizza/PolizzaPage.vue")
                    .default,
                name: "polizza_index",
                meta: {
                    label: "Polizze",
                    title: "Elenco Polizze",
                    requiresAuth: true,
                    icon: "mdi-file-document-plus",
                    showIn: ["SidebarNav"],
                },
            },
            {
                path: "/new/polizza",
                component: require("./components/polizza/PolizzaForm.vue")
                    .default,
                name: "nuova_polizza",
                meta: {
                    requiresAuth: true,
                    title: "Nuova Polizza",
                    icon: "mdi-file-document-plus",
                    showIn: ["SidebarNav"],
                },
            },
            {
                path: "/modifica/polizza/:id",
                component: require("./components/polizza/PolizzaForm.vue")
                    .default,
                name: "polizza_edit",
                meta: {
                    requiresAuth: true,
                    title: "Modifica Polizza",
                    icon: "mdi-file-document-plus",
                },
            },

            // CLIENTE
            {
                path: "/cliente",
                component: require("./components/page/ClientePage.vue").default,
                // component: require('./components/cliente/ClienteForm.vue').default,
                name: "cliente_index",
                meta: {
                    requiresAuth: true,
                    title: "Clienti",
                    icon: "mdi-account-multiple",
                },
            },
            {
                path: "/modifica/cliente/:id",
                // component: require('./components/page/ClientePage.vue').default,
                component: require("./components/cliente/ClienteForm.vue")
                    .default,
                name: "cliente_edit",
                meta: {
                    requiresAuth: true,
                    title: "Modifica Cliente",
                    //TODO | ICON | [mdi-accounts,mdi-plus] cosi si possono mettere piu icone
                    icon: "mdi-accounts",
                },
            },
            {
                path: "/new/cliente",
                component: require("./components/cliente/ClienteForm.vue")
                    .default,
                name: "cliente_store",
                meta: {
                    requiresAuth: true,
                    title: "Nuova cliente",
                    icon: "mdi-store",
                },
            },

            // COMPAGNIA
            {
                path: "/compagnia",
                component: require("./components/page/CompagniaPage.vue")
                    .default,
                // component: require('./components/compagnia/CompagniaForm.vue').default,
                name: "compagnia_index",
                meta: {
                    requiresAuth: true,
                    title: "Elenco dei Fornitori",
                    icon: "mdi-store",
                },
            },
            {
                path: "/modifica/compagnia/:id",
                // path: '/modifica/compagnia/:fornitore_id',
                component: require("./components/compagnia/CompagniaForm.vue")
                    .default,
                name: "compagnia_edit",
                meta: {
                    requiresAuth: true,
                    title: "Modifica Fornitore",
                    icon: "mdi-store-edit",
                },
            },
            {
                path: "/new/compagnia",
                component: require("./components/compagnia/CompagniaForm")
                    .default,
                name: "compagnia_store",
                meta: {
                    requiresAuth: true,
                    title: "Nuovo Fornitore",
                    icon: "mdi-store",
                },
            },

            // COLLABORATORE
            {
                path: "/collaboratore",
                component: require("./components/page/CollaboratorePage")
                    .default,
                name: "collaboratore_index",
                meta: {
                    requiresAuth: true,

                    title: "Collaboratori",
                    icon: "mdi-account-multiple",
                },
            },
            {
                path: "/modifica/collaboratore/:id",
                component:
                    require("./components/collaboratore/CollaboratoreDetails")
                        .default,
                name: "collaboratore_edit",
                meta: {
                    requiresAuth: true,
                    title: "Modifica Collaboratore",
                    icon: "mdi-account",
                },
            },
            {
                path: "/new/collaboratore",
                component:
                    require("./components/collaboratore/CollaboratoreForm")
                        .default,
                name: "collaboratore_store",
                meta: {
                    requiresAuth: true,
                    title: "Nuovo Collaboratore",
                    icon: "mdi-account-plus",
                },
            },
            // Quotazione
            {
                path: "/auto_storiche",
                component: require("./components/page/QuotazionePage").default,
                name: "quotazione_index",
                meta: {
                    requiresAuth: true,
                    title: "Quotazioni",
                    icon: "mdi-account-multiple",
                },
            },
            {
                path: "/quotazione",
                component: require("./components/page/AutoStorichePage")
                    .default,
                name: "auto_storiche_index",
                meta: {
                    requiresAuth: true,
                    title: "Auto Storiche",
                    icon: "mdi-car",
                },
            },
            {
                path: "/modifica/quotazione/:id",
                component: require("./components/quotazione/QuotazioneForm")
                    .default,
                name: "quotazione_edit",
                meta: {
                    requiresAuth: true,
                    title: "Modifica quotazione",
                    icon: "mdi-account",
                },
            },
            {
                path: "/modifica/quotazione_diretta/:id",
                component:
                    require("./components/quotazione/QuotazioneDirettaForm")
                        .default,
                name: "quotazione_diretta_edit",
                meta: {
                    requiresAuth: true,
                    title: "Modifica quotazione",
                    icon: "mdi-account",
                },
            },
            {
                path: "/new/quotazione",
                component: require("./components/quotazione/QuotazioneForm")
                    .default,
                name: "quotazione_store",
                meta: {
                    requiresAuth: true,
                    title: "Nuova quotazione",
                    icon: "mdi-account-plus",
                },
            },
            // {
            //     path: "/new/exports",
            //     component: require("./components/exports/LinkEsportazione")
            //         .default,
            //     name: "quotazione_store",
            //     meta: {
            //         requiresAuth: true,
            //         title: "Esportazioni",
            //         icon: "mdi-account-plus",
            //     },
            // },
        ],
    },

    {
        path: "/login",
        component: require("./components/page/LogInPage.vue").default,
        name: "login",
    },
    // {
    //     path: "/test/box_firma",
    //     component: require("./components/ui/form/box-firma-test.vue").default,
    //     name: "test_box_firma",
    // },
    {
        path: "/test/data_table",
        component: require("./components/ui/DataTableSpatieQueryBuilderTest.vue").default,
        name: "data_table_test",
    },
    // {
    //     path: "/test/prodotto_assicurativo",
    //     component: require("./components/ProdottoAssicurativoForm").default,
    //     name: "test_prodotto_assicurativo",
    // },
    // {
    //     path: "/test/server-side-data-table",
    //     component: require("./components/ui/datatable/ServerSideDataTable.test").default,
    //     name: "test_prodotto_assicurativo",
    // },
    // {
    //     // ./components/ProdottoAssicurativoForm.vue
    //     path: "/test/griglia_dinamica",
    //     component: require("./components/GrigliaDinamica.test").default,
    //     name: "test_griglia_dinamica",
    // },
    // {
    //     // ./components/ProdottoAssicurativoForm.vue
    //     path: "/test/formatted",
    //     component: require("./components/ui/form/text-formatted.test").default,
    //     name: "test_text-formatted",
    // },
    // {
    //   path: '/test/file-upload',
    //   component: require('./components/ui/form/file-upload.test').default,
    //   name: 'test_file-upload'
    // }
    // ,
    // {
    //     path: "/test/dynamic-dropdown",
    //     component: require("./components/ui/form/dynamic-dropdown.test")
    //         .default,
    //     name: "dynamic-dropdown",
    // },
    // {
    //     path: "/test/dropzone",
    //     component: require("./components/ui/form/dropzone-upload.test").default,
    //     name: "dropzone",
    // },
    // {
    //     path: "/test/docx-viewer",
    //     component: require("./components/ui/docx/docx-viewer.test").default,
    //     name: "dropzone",
    // },

    // {
    //     path: "/:pathMatch(.*)*",
    //     name: "NotFound",
    //     component: require("./components/NotFound").default,
    // },
];
