<template>
  <div>
    <!-- v-currency-field="currencyOptions" -->
    <v-text-field
      type="number"
      
      min="0"
      class="align__right"
      v-bind="$attrs"
      :value="value"
      v-on="$listeners"
      color="primary"
      @input="(v) => $emit('input', toDotFormat(v))">
      <!-- SLOT EREDITATI -->
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
  export default {
    props: ["value"],
    methods: {
      toComma(number) {
        // return new Intl.NumberFormat("it-IT", {
        //   style: "currency",
        //   currency: "EUR",
        //   useGrouping: true,
        //   minimumIntegerDigits: 2,
        //   minimumFractionDigits: 2,
        // }).format(number);
        number
      },
      toDotFormat(string) {
        return Number(string.replace(",", "."));
      },
    },
    data() {
      return {
        currencyOptions: {
          locale: "it-IT",
          currency: "EUR",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      };
    },
  };
</script>

<style lang="css" scoped>
  .align__right >>> input {
    text-align: right !important;
  }
</style>
