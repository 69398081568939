<template>
  <section class="model__form">
    <div id="CollaboratoreForm">
      <v-toolbar dense>
        <v-toolbar-title>
          <v-card-title v-if="collaboratore" class="h4" primary-title>
            <span v-if="!collaboratore.id">Dati Collaboratore {{ mode }}</span>
            <span v-if="collaboratore.id">{{
              collaboratore.nome + " " + (collaboratore.cognome || "")
            }}</span>
          </v-card-title>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="mode === 'EDIT_MODE'"
            color="primary"
            class="primary"
            dark
            @click="aggiorna()"
            >Aggiorna</v-btn
          >
          <!-- :disabled="loading" -->

          <v-btn
            v-if="mode === 'ADD_NEW_MODE'"
            color="primary"
            class="primary"
            dark
            @click="aggiungi()"
            >Aggiungi</v-btn
          >
          <!-- <v-btn color="indigo" dark>Link Three</v-btn> -->
        </v-toolbar-items>
      </v-toolbar>

      <v-card background-color="white" :loading="loading">
        <v-card-text>
          <v-row class="pt-0 px-0">
            <v-col col="12" sm="9">
              <!-- FORM START -->
              <div v-if="collaboratore" class="v-main__wrap">
                <!-- active -->
                <v-col cols="12" sm="6">
                  <v-switch
                    id="active"
                    v-model="collaboratore.active"
                    data-cy="active"
                    label="Active"></v-switch>
                </v-col>

                <section id="collaboratore__anagrafica" class="fieldset">
                  <h3 class="h6 fieldset__title">Anagrafica</h3>
                  <div class="fieldset__content row">
                    <div id="Sesso" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="sesso">Sesso/Azienda</label>
                      <select
                        id="sesso"
                        data-cy="sesso:select"
                        :class="{
                          'is-invalid': errors.sesso,
                        }"
                        v-model="collaboratore.sesso"
                        class="form-control-sm form-control custom-select">
                        <option value="M">Maschio</option>
                        <option value="F">Femmina</option>
                        <option value="A">Azienda</option>
                      </select>
                      <invalid-feedback :errors="errors.sesso" />
                    </div>

                    <div
                      id="Nome"
                      v-if="collaboratore.sesso !== 'A'"
                      class="form-group col-lg-5 py-0 px-1 mb-3">
                      <label for="nome">Nome:</label>
                      <input
                        data-cy="nome"
                        :class="{
                          'is-invalid': errors.nome,
                        }"
                        v-model="collaboratore.nome"
                        type="text"
                        id="nome"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.nome" />
                    </div>

                    <div
                      id="Cognome"
                      v-if="collaboratore.sesso !== 'A'"
                      class="form-group col-lg-4 py-0 px-1 mb-3">
                      <label for="cognome">Cognome:</label>
                      <input
                        data-cy="cognome"
                        :class="{
                          'is-invalid': errors.cognome,
                        }"
                        v-model="collaboratore.cognome"
                        type="text"
                        id="cognome"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.cognome" />
                    </div>
                    <div
                      id="ragioneSociale"
                      v-if="collaboratore.sesso === 'A'"
                      class="form-group col-lg-8 py-0 px-1 mb-3">
                      <label for="ragioneSociale">Ragione Sociale:</label>
                      <input
                        data-cy="ragioneSociale"
                        :class="{
                          'is-invalid': errors.nome,
                        }"
                        v-model="collaboratore.nome"
                        type="text"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.nome" />
                    </div>
                  </div>
                </section>

                <section id="collaboratore__nascita" class="fieldset">
                  <div class="fieldset__content row">
                    <div
                      v-if="collaboratore.sesso !== 'A'"
                      class="form-group col-lg-6 py-0 px-1 mb-3">
                      <label for="comuneNascita"
                        >Comune/Luogo di nascita:</label
                      >
                      <input
                        data-cy="comuneNascita"
                        :class="{
                          'is-invalid': errors.comuneNascita,
                        }"
                        v-model="collaboratore.comuneNascita"
                        type="text"
                        id="comuneNascita"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.comuneNascita" />

                      <autocomplete
                        label="Comune"
                        data-cy="comuneNascita"
                        clearable
                        color="primary"
                        v-model="collaboratore.comune_nascita_id"
                        item-value="id"
                        item-text="nome"
                        resource="/api/autocomplete/comune" />
                    </div>
                    <div
                      v-if="collaboratore.sesso !== 'A'"
                      id="provinciaNascita"
                      class="form-group col-lg-2 py-0 px-1 mb-3">
                      <label for="provinciaNascita">Provincia di</label>
                      <input
                        data-cy="provinciaNascita"
                        :class="{
                          'is-invalid': errors.provinciaNascita,
                        }"
                        v-model="collaboratore.provinciaNascita"
                        type="text"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.provinciaNascita" />
                    </div>

                    <div
                      v-if="collaboratore.sesso !== 'A'"
                      id="Datanascita"
                      class="form-group col-lg-4 py-0 px-1 mb-3">
                      <label for="dataNascita">Data di Nascita:</label>

                      <date-picker
                        data-cy="dataNascita:date"
                        v-model="collaboratore.dataNascita"
                        value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY"></date-picker>
                    </div>
                  </div>

                  <div
                    class="fieldset__content row"
                    v-if="collaboratore.sesso !== 'A'">
                    <div id="Cf" class="form-group col-lg-12 py-0 px-1 mb-3">
                      <label for="cf">Cf:</label>
                      <input
                        data-cy="cf"
                        :class="{
                          'is-invalid': errors.cf,
                        }"
                        v-model="collaboratore.cf"
                        type="text"
                        id="cf"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.cf" />
                    </div>
                  </div>
                </section>

                <!-- INDIRIZZO_RESIDENZA  SEDE operativa -->
                <section id="indirizzo__residenza" class="fieldset">
                  <h3
                    v-if="collaboratore.sesso !== 'A'"
                    class="h6 fieldset__title">
                    Residenza
                  </h3>
                  <h3
                    v-if="collaboratore.sesso === 'A'"
                    class="h6 fieldset__title">
                    Sede Legale/Operativa
                  </h3>
                  <div class="fieldset__content row">
                    <div
                      id="Indirizzo"
                      class="form-group col-lg-5 py-0 px-1 mb-3">
                      <label for="indirizzo">Indirizzo:</label>
                      <input
                        data-cy="indirizzo"
                        :class="{
                          'is-invalid': errors.indirizzo,
                        }"
                        v-model="collaboratore.indirizzo"
                        type="text"
                        id="indirizzo"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.indirizzo" />
                    </div>

                    <div id="Civico" class="form-group col-lg-1 py-0 px-1 mb-3">
                      <label for="civico">Civico:</label>
                      <input
                        data-cy="civico"
                        :class="{
                          'is-invalid': errors.civico,
                        }"
                        v-model="collaboratore.civico"
                        type="text"
                        id="civico"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.civico" />
                    </div>

                    <div id="Citta" class="form-group col-lg-4 py-0 px-1 mb-3">
                      <label for="citta">Citta:</label>
                      <input
                        data-cy="citta"
                        :class="{
                          'is-invalid': errors.citta,
                        }"
                        v-model="collaboratore.citta"
                        type="text"
                        id="citta"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.citta" />
                    </div>

                    <div id="Cap" class="form-group col-lg-1 py-0 px-1 mb-3">
                      <label for="cap">Cap:</label>
                      <input
                        data-cy="cap"
                        :class="{
                          'is-invalid': errors.cap,
                        }"
                        v-model="collaboratore.cap"
                        type="text"
                        id="cap"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.cap" />
                    </div>

                    <div id="Prov" class="form-group col-lg-1 py-0 px-1 mb-3">
                      <label for="prov">Prov:</label>
                      <input
                        data-cy="prov"
                        :class="{
                          'is-invalid': errors.prov,
                        }"
                        v-model="collaboratore.prov"
                        type="text"
                        id="prov"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.prov" />
                    </div>
                  </div>
                </section>
                <section id="collaboratore__contatti" class="fieldset">
                  <h3 class="h6 fieldset__title">Contatti</h3>
                  <div class="fieldset__content row">
                    <div id="Cell" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="cell">Cell:</label>
                      <input
                        data-cy="cell"
                        :class="{
                          'is-invalid': errors.cell,
                        }"
                        v-model="collaboratore.cell"
                        type="text"
                        id="cell"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.cell" />
                    </div>

                    <div id="Tel" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="tel">Tel:</label>
                      <input
                        data-cy="tel"
                        :class="{
                          'is-invalid': errors.tel,
                        }"
                        v-model="collaboratore.tel"
                        type="text"
                        id="tel"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.tel" />
                    </div>

                    <div id="Email" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="email">Email:</label>
                      <input
                        data-cy="email"
                        :class="{
                          'is-invalid': errors.email,
                        }"
                        v-model="collaboratore.email"
                        type="email"
                        id="email"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.email" />
                    </div>

                    <div id="Pec" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="pec">Pec:</label>
                      <input
                        data-cy="pec"
                        :class="{
                          'is-invalid': errors.pec,
                        }"
                        v-model="collaboratore.pec"
                        type="email"
                        id="pec"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.pec" />
                    </div>
                  </div>
                </section>
                <section
                  v-if="collaboratore.sesso !== 'A'"
                  id="collaboratore__documento"
                  class="fieldset">
                  <h3 class="h6 fieldset__title">Documento di identità</h3>
                  <div class="fieldset__content row">
                    <div
                      id="Documentonumero"
                      class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="documentoNumero">Numero:</label>
                      <input
                        data-cy="documentoNumero"
                        :class="{
                          'is-invalid': errors.documentoNumero,
                        }"
                        v-model="collaboratore.documentoNumero"
                        type="text"
                        id="documentoNumero"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.documentoNumero" />
                    </div>

                    <div id="Documentotipo" class="form-group col-lg-3">
                      <label for="documentoTipo">Tipo:</label>
                      <select
                        id="documentoTipo"
                        data-cy="documentoTipo"
                        :class="{
                          'is-invalid': errors.documentoTipo,
                        }"
                        v-model="collaboratore.documentoTipo"
                        class="form-control-sm form-control custom-select">
                        <option value="CARTA_IDENTITA">CARTA_IDENTITA</option>
                        <option value="PASSAPORTO">PASSAPORTO</option>
                      </select>
                      <invalid-feedback :errors="errors.documentoTipo" />
                    </div>

                    <div
                      id="Documentoscadenza"
                      class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="documentoScadenza">Scadenza:</label>

                      <date-picker
                        data-cy="documentoScadenza:date"
                        v-model="collaboratore.documentoScadenza"
                        :class="{
                          'is-invalid': errors.documentoScadenza,
                        }"
                        value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY"></date-picker>
                      <invalid-feedback :errors="errors.documentoScadenza" />
                    </div>

                    <div
                      id="Documentorilasciatoda"
                      class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="documentoRilasciatoDa">Rilasciato Da:</label>
                      <input
                        data-cy="documentoRilasciatoDa"
                        :class="{
                          'is-invalid': errors.documentoRilasciatoDa,
                        }"
                        v-model="collaboratore.documentoRilasciatoDa"
                        type="text"
                        id="documentoRilasciatoDa"
                        class="form-control-sm form-control" />
                      <invalid-feedback
                        :errors="errors.documentoRilasciatoDa" />
                    </div>
                  </div>
                </section>
                <section
                  id="collaboratore__professionale_fatturazione"
                  class="fieldset">
                  <h3 class="h6 fieldset__title">
                    Dati professionali e contabili
                  </h3>
                  <div class="fieldset__content row">
                    <div id="Tipo" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="tipo">Tipo:</label>
                      <select
                        id="tipo"
                        data-cy="tipo"
                        :class="{
                          'is-invalid': errors.tipo,
                        }"
                        v-model="collaboratore.tipo"
                        class="form-control-sm form-control custom-select">
                        <option value="COLLABORATORE">COLLABORATORE</option>
                        <option value="AGENTE">AGENTE</option>
                        <option value="BROKER">BROKER</option>
                      </select>
                      <invalid-feedback :errors="errors.tipo" />
                    </div>

                    <div
                      id="Iscrizionerui"
                      class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="iscrizioneRUI">Iscrizione R.U.I.:</label>
                      <input
                        data-cy="iscrizioneRUI"
                        :class="{
                          'is-invalid': errors.iscrizioneRUI,
                        }"
                        v-model="collaboratore.iscrizioneRUI"
                        type="text"
                        id="iscrizioneRUI"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.iscrizioneRUI" />
                    </div>

                    <div
                      id="Iscrizioneruidata"
                      class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="iscrizioneRUIData">Data Iscrizione:</label>

                      <date-picker
                        data-cy="iscrizioneRUIData:date"
                        v-model="collaboratore.iscrizioneRUIData"
                        :class="{
                          'is-invalid': errors.iscrizioneRUIData,
                        }"
                        value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY"></date-picker>

                      <invalid-feedback :errors="errors.iscrizioneRUIData" />
                    </div>

                    <div id="Piva" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="piva">Partita Iva:</label>
                      <input
                        data-cy="piva"
                        :class="{
                          'is-invalid': errors.piva,
                        }"
                        v-model="collaboratore.piva"
                        type="text"
                        id="piva"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.piva" />
                    </div>

                    <!-- IBAN Conto Premi: -->
                    <div id="Iban" class="form-group col-lg-4 py-0 px-1 mb-3">
                      <label for="IBAN">IBAN Conto Premi:</label>
                      <input
                        data-cy="IBAN"
                        :class="{
                          'is-invalid': errors.IBAN,
                        }"
                        v-model="collaboratore.IBAN"
                        type="text"
                        id="IBAN"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.IBAN" />
                    </div>

                    <!-- IBAN Conto Patrimonio: -->
                    <div class="form-group col-lg-4 py-0 px-1 mb-3">
                      <label for="IBAN">IBAN Conto Patrimonio:</label>
                      <input
                        data-cy="IBAN"
                        :class="{
                          'is-invalid': errors.IBANPatrimonio,
                        }"
                        v-model="collaboratore.IBANPatrimonio"
                        type="text"
                        id="IBANPatrimonio"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.IBANPatrimonio" />
                    </div>

                    <div class="form-group col-lg-4 py-0 px-1 mb-3">
                      <autocomplete
                        label="Tipologia Rimessa"
                        v-model="collaboratore.tipologiaRimessa"
                        item-value="id"
                        item-text="label"
                        dense
                        resource="/api/autocomplete/tipologia_rimessa" />
                    </div>
                    <!-- <pre>{{ collaboratore }}</pre> -->

                    <!-- <div
                    id="Codiceunivocopa"
                    class="form-group col-lg-3 py-0 px-1 mb-3">
                    <label for="codiceUnivocoPA">Codice Univoco PA:</label>
                    <input
                      data-cy="codiceUnivocoPA"
                      :class="{
                        'is-invalid': errors.codiceUnivocoPA,
                      }"
                      v-model="collaboratore.codiceUnivocoPA"
                      type="text"
                      id="codiceUnivocoPA"
                      class="form-control-sm form-control" />
                    <invalid-feedback :errors="errors.codiceUnivocoPA" />
                  </div> -->

                    <div
                      id="Codicedestinatario"
                      class="form-group col-lg-3 py-0 px-1 mb-3">
                      <label for="codiceDestinatario"
                        >Codice Destinatario:</label
                      >
                      <input
                        data-cy="codiceDestinatario"
                        :class="{
                          'is-invalid': errors.codiceDestinatario,
                        }"
                        v-model="collaboratore.codiceDestinatario"
                        type="text"
                        id="codiceDestinatario"
                        class="form-control-sm form-control" />
                      <invalid-feedback :errors="errors.codiceDestinatario" />
                    </div>
                  </div>
                  <!-- numeroREA -->
                  <v-col cols="12" sm="3">
                    <v-text-field
                      id="numeroREA"
                      data-cy="numeroREA"
                      v-model="collaboratore.numeroREA"
                      type="text"
                      :error-messages="errors.numeroREA"
                      :error="errors.numeroREA && errors.numeroREA.length > 0"
                      label="NumeroREA"></v-text-field>
                  </v-col>
                </section>
                <section
                  id="collaboratore__professionale_fatturazione"
                  class="fieldset">
                  <h3 class="h6 fieldset__title">Permessi</h3>
                  <div class="fieldset__content row">
                    <div id="Web" class="form-group col-lg-3 py-0 px-1 mb-3">
                      <div class="form-check">
                        <input
                          data-cy="web"
                          :class="{
                            'is-invalid': errors.web,
                          }"
                          v-model="collaboratore.web"
                          type="hidden"
                          value="0"
                          class="form-check-input" />

                        <input
                          data-cy="web"
                          type="checkbox"
                          value="1"
                          class="form-check-input" />

                        <label for="web" class="form-check-label">Web: </label>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <!-- FORM END -->
            </v-col>

            <v-col col="12" sm="3">
              <v-expansion-panels multiple v-model="ui.panel">
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-3 p-3">
                    <div v-if="collaboratore">
                      <h5 class="h6 fieldset__title">Settori e Provvigioni</h5>
                      <VSpacer />
                      <span
                        :key="item.nome"
                        v-for="item in collaboratore.provvigioni_settori">
                        <span v-if="item.perc_provvigione">
                          {{ item.nome }}:{{ item.perc_provvigione }}%
                        </span>
                      </span>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <provvigioni-settori-form
                      v-if="collaboratore"
                      id="collaboratore-provvigioni-settore"
                      v-model="collaboratore.provvigioni_settori" />

                    <v-text-field
                      label="Percentuale Competenze"
                      class="mt-5 p-0"
                      v-model="collaboratore.percProvvigioneCompetenze">
                    </v-text-field>

                    <v-text-field
                      label="Percentuale Diritti"
                      class="mt-5 p-0"
                      v-model="collaboratore.precProvvigioneDiritti">
                    </v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel v-if="collaboratore.sesso=='A'">
                  <v-expansion-panel-header class="pl-3 p-3">
                    <h5 class="h6 fieldset__title">Responsabile Intermediazione</h5>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="p-0">
                    <persona-fisica-form  v-model="collaboratore.responsabileIntermediazione"/>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-3 p-3">
                    <h5  data-cy-="punto_vendita_superiore"
                    class="h6 fieldset__title"> Punto vendita superiore</h5>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="p-0">
                    <autocomplete
                      item-value="id"
                      item-text="label"
                      v-model="collaboratore.punto_vendita_superiore_id"
                      :resource="`/api/autocomplete/punto_vendita_superiore/${collaboratore.id}`">
                    </autocomplete>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
    </div>
  </section>
</template>

<script>
  /* eslint-disable */
  import DatePicker from "vue2-datepicker";
  import { mapActions } from "vuex";
  import { mapFields } from "vuex-map-fields";
  import PersonaFisicaForm from "../persona_fisica/PersonaFisicaForm.vue";
  import Autocomplete from "../ui/Autocomplete.vue";
  import InvalidFeedbackVue from "../ui/InvalidFeedback.vue";
  import ProvvigioniSettoriForm from "../ui/ProvvigioniSettoriForm.vue";

  export default {
    // name:"collaboratore-form",
    components: {
      Autocomplete,
      "date-picker": DatePicker,
      "invalid-feedback": InvalidFeedbackVue,
      "provvigioni-settori-form": ProvvigioniSettoriForm,
      "persona-fisica-form": PersonaFisicaForm,
    },

    mounted() {
      if (this.mode == "EDIT_MODE") {
        this.$store.dispatch("CollaboratoreModule/show", this.$route.params.id);
      } else if (this.mode == "ADD_NEW_MODE") {
        this.ui.toolbarTitle = "Aggiungi un nuovo collaboratore";
        this.$store.dispatch("CollaboratoreModule/create", {});
      }
    },
    data() {
      return {
        tab: 0,
        // TODO: Forse da spostare nei valori di default
        ui: {
          toolbarTitle: "",
          tabs_sx: 0,
          // panel: [1],
          panel: [],
        },
      };
    },
    computed: {
      ...mapFields("CollaboratoreModule", [
        "collaboratore",
        "loading",
        "errors",
      ]),
      // panel(){
      //    return this.collaboratore.sesso == 'A' ? [1] : []
      // },
      mode() {
        return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
      },
    },
    methods: {
      ...mapActions("CollaboratoreModule", ["setAggiorna", "store", "create"]),
      aggiorna() {
        // console.log("CollaboratoreForm - aggiorna", this.collaboratore);
        this.setAggiorna(this.collaboratore);
      },
      aggiungi() {
        this.store(this.collaboratore);
      },
    },
  };
</script>
<style>
  .v-card.v-sheet.theme--light {
    background-color: white;
  }
</style>
