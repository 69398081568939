<template>
    <v-container fluid :class="containerPadding">
        <v-alert text :type="alert.type" v-if="alert.type">
            <ul v-if="this.errors.length > 0">
                <li v-for="(error, index) in this.errors" :key="index">{{ error }}</li>
            </ul>
            {{ alert.message }}
        </v-alert>
        <v-card-title>
            Cliente {{ quotazione.first_name + " " + quotazione.last_name }}
        </v-card-title>

        <!-- {{ quotazione.stato_quotazione_id }} {{   [9,10,11].includes(quotazione.stato_quotazione_id) }} && {{ roleIs('super_admin')  }} -->
        <v-tabs>
            <v-tab> Raccolta dati </v-tab>

            <v-tab> Documenti </v-tab>

            <v-tab-item>
                <div class="row pa-4">
                    <div class="col-9">

                        <v-card>
                            <v-card-text>
                                <section class=" fieldset">
                                    <div class="fieldset__content row">
                                        <v-card-title>Dati anagrafici</v-card-title>
                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <v-select autocomplete="new-password"
                                                :items="[{ name: 'Maschio', value: 'M' }, { name: 'Femmina', value: 'F' }, { name: 'Azienda', value: 'A' }]"
                                                item-text="name" item-value="value" label="Sesso:"
                                                data-cy="quotazione.gender" v-model="quotazione.gender"></v-select>
                                            <invalid-feedback :errors="errors.gender" />
                                        </div>
                                        <div id="first_name" class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="quotazione.gender != 'A'">
                                            <label for="first_name">Nome:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="text" id="first_name" data-cy="quotazione.first_name"
                                                v-model="quotazione.first_name" />

                                            <invalid-feedback :errors="errors.first_name" />
                                        </div>

                                        <div id="last_name" class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="quotazione.gender != 'A'">
                                            <label for="last_name">Cognome:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="text" data-cy="quotazione.last_name" id="last_name"
                                                v-model="quotazione.last_name" />

                                            <invalid-feedback :errors="errors.last_name" />
                                        </div>
                                        <div id="company_name" class="form-group col-md-4 py-0 px-1 mb-3"
                                            v-if="quotazione.gender == 'A'">
                                            <label for="company_name">Ragione Sociale:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="text" data-cy="quotazione.company_name" id="company_name"
                                                v-model="quotazione.company_name" />

                                            <invalid-feedback :errors="errors.company_name" />
                                        </div>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="email">Email:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="email" data-cy="quotazione.email" v-model="quotazione.email"
                                                name="email" />

                                            <invalid-feedback :errors="errors.email" />
                                        </div>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3" v-if="quotazione.gender != 'A'">
                                            <div class="mb-6" v-if="false">Active picker:
                                                <code>{{ activePicker || 'null' }}</code>
                                            </div>
                                            <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition"
                                                offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="quotazione.date_of_birth" label="Data di nascita"
                                                        prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                        v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="quotazione.date_of_birth"
                                                    :active-picker.sync="activePicker"
                                                    :max="(new Date().getFullYear() - 17).toString()" min="1923-01-01"
                                                    locale="it-it"></v-date-picker>
                                            </v-menu>
                                            <invalid-feedback :errors="errors.date_of_birth" />
                                        </div>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3" v-if="quotazione.gender != 'A'">
                                            <teo-autocomplete v-model="quotazione.state_birth_id" label="Nazione di nascita"
                                                item-text="nome_it" item-value="id" server-side-search
                                                resource="/api/autocomplete/nazione">
                                            </teo-autocomplete>
                                            <invalid-feedback :errors="errors.state_birth_id" />
                                        </div>
                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="quotazione.tax_code">Codice Fiscale:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="text" data-cy="quotazione.tax_code" v-model="quotazione.tax_code"
                                                name="tax_code" />

                                            <invalid-feedback :errors="errors.tax_code" />
                                        </div>
                                        <div class="form-group col-md-4 py-0 px-1 mb-3" v-if="quotazione.gender == 'A'">
                                            <label for="vat_number">Partita Iva:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="text" data-cy="quotazione.vat_number" v-model="quotazione.vat_number"
                                                name="vat_number" />

                                            <invalid-feedback :errors="errors.vat_number" />
                                        </div>
                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="phone">Telefono:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="phone" data-cy="quotazione.phone" v-model="quotazione.phone"
                                                name="phone" />

                                            <invalid-feedback :errors="errors.phone" />
                                        </div>
                                    </div>

                                    <div class="fieldset__content row">
                                        <v-card-title v-if="quotazione.gender != 'A'">Residenza</v-card-title>
                                        <v-card-title v-if="quotazione.gender == 'A'">Sede legale</v-card-title>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="address">Indirizzo:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="address" data-cy="quotazione.address" v-model="quotazione.address"
                                                name="address" />

                                            <invalid-feedback :errors="errors.address" />
                                        </div>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="street_number">Civico:</label>

                                            <input class="form-control-sm form-control" autocomplete="n"
                                                type="street_number" data-cy="quotazione.street_number"
                                                v-model="quotazione.street_number" name="street_number" />

                                            <invalid-feedback :errors="errors.street_number" />
                                        </div>
                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="zip_code">Cap:</label>

                                            <input class="form-control-sm form-control" autocomplete="n" type="zip_code"
                                                data-cy="quotazione.zip_code" v-model="quotazione.zip_code"
                                                name="zip_code" />

                                            <invalid-feedback :errors="errors.zip_code" />
                                        </div>
                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <teo-autocomplete v-model="quotazione.city_id" label="City" item-text="nome"
                                                item-value="codice" server-side-search resource="/api/autocomplete/comune">
                                            </teo-autocomplete>

                                            <invalid-feedback :errors="errors.city_id" />
                                        </div>

                                        <div id="provincia" class="form-group col-md-4 py-0 px-1 mb-3">
                                            <teo-autocomplete autocomplete="new-password" name="state_code"
                                                label="Provincia" item-text="nome" item-value="codice" server-side-search
                                                resource="/api/autocomplete/province" data-cy="quotazione.state_code"
                                                v-model="quotazione.state_code" disabled>
                                            </teo-autocomplete>

                                            <invalid-feedback :errors="errors.state_code" />
                                        </div>
                                    </div>

                                    <div class="fieldset__content row">
                                        <v-card-title>Varie</v-card-title>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <v-select autocomplete="new-password" :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                                @change="aggiungiVeicoliModerni" label="Veicoli uso quotidiano:"
                                                data-cy="quotazione.daily_use" v-model="quotazione.daily_use"></v-select>

                                            <invalid-feedback />
                                        </div>

                                        <div class="form-group col-md-4 py-0 px-1 mb-3">
                                            <label for="club">Club:</label>

                                            <input class="form-control-sm form-control" autocomplete="new-password"
                                                type="text" id="club" name="club" data-cy="quotazione.club"
                                                v-model="quotazione.club" />

                                            <invalid-feedback :errors="errors.club" />
                                        </div>
                                    </div>

                                    <div class="fieldset__content row">
                                        <auto-storiche-table v-if="quotazione.id" :value="quotazione"
                                            :veicoli_storici="veicoli_storici"
                                            @input="salvaVeicoliStorici"></auto-storiche-table>
                                    </div>

                                    <div class="fieldset__content row">
                                        <invalid-feedback :errors="errors.veicoli_moderni" />
                                        <div class="v-toolbar__title">Veicoli Moderni</div>
                                        <v-simple-table class="mb-5">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">Marca</th>
                                                        <th class="text-left">Modello</th>
                                                        <th class="text-left">Anno immatricolazione</th>

                                                        <th class="text-left">Targa</th>
                                                        <th class="text-left">Grado di parentela</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="veicolo in veicoli_moderni" :key="veicolo.id">
                                                        <td data-cy="td-item.brand">
                                                            <v-text-field v-model="veicolo.brand" label="Immetti la Marca"
                                                                :rules="required"></v-text-field>
                                                        </td>
                                                        <td data-cy="td-item.model">
                                                            <v-text-field v-model="veicolo.model" label="Immetti il modello"
                                                                :rules="required"></v-text-field>
                                                        </td>
                                                        <td data-cy="td-item.year">
                                                            <v-text-field v-model="veicolo.year" label="Immetti l'anno"
                                                                :rules="required"></v-text-field>
                                                        </td>
                                                        <td data-cy="td-item.plate_number">
                                                            <v-text-field v-model="veicolo.plate_number"
                                                                label="Immetti la targa" :rules="required"></v-text-field>
                                                        </td>
                                                        <td>
                                                            <v-select :items="['Contraente', 'Coniuge', 'Familiare']"
                                                                v-model="veicolo.relation"
                                                                label="Grado di parentela"></v-select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </div>

                                    <v-container fluid>
                                        <v-textarea clearable clear-icon="mdi-close-circle" label="Note"
                                            data-cy="quotazione.note" v-model="quotazione.note"></v-textarea>
                                    </v-container>
                                </section>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="col-3">
                        <section>
                            <v-card class="mb-2" v-if="quotazione.stato_quotazione">
                                <v-card-title>Stato quotazione:</v-card-title>
                                <v-card-text>
                                    <v-chip class="ma-2" color="primary" text-color="white" v-if="!showCambiaStato">
                                        {{ quotazione.stato_quotazione.nome }}
                                    </v-chip>
                                    <v-select :items="stati_quotazione" item-text="stato_quotazione.nome"
                                        item-value="stato_quotazione.id" v-model="quotazione.stato_quotazione_id"
                                        label="Cambia Stato" v-if="showCambiaStato" @change="cambioStato"></v-select>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title v-if="quotazione.gros_premium">Premio</v-card-title>
                                <v-card-title v-if="!quotazione.gros_premium">Compila il form</v-card-title>
                                <v-card-text>
                                    <v-row align="center">
                                        <div :class="{ 'text-h2': !quotazione.forced_gros_premium }"
                                            v-if="quotazione.gros_premium">
                                            <div :class="{
                                                'text-decoration-line-through': quotazione.forced_gros_premium,
                                            }">
                                                € {{ quotazione.gros_premium }}
                                            </div>
                                        </div>
                                        <div class="text-h2" v-if="quotazione.gros_premium">
                                            <div v-if="quotazione.forced_gros_premium">
                                                € {{ quotazione.forced_gros_premium }}
                                            </div>
                                        </div>
                                    </v-row>

                                    <div class="form-group" v-if="forceCheck">
                                        <input class="form-control-sm form-control" autocomplete="new-password" type="text"
                                            data-cy="quotazione.forced_gros_premium"
                                            v-model="quotazione.forced_gros_premium" name="premio_forzato" />
                                    </div>
                                </v-card-text>

                                <v-list-item>
                                    <date-picker label="Data di Emissione" data-cy="quotazione.effective_date:Date"
                                        v-model="quotazione.effective_date" value-type="YYYY-MM-DD"
                                        format="DD/MM/YYYY"></date-picker>
                                </v-list-item>

                                <v-list-item>
                                    <v-list-item-content>
                                        <v-btn data-cy="" small color="primary" dark class="mb-2" @click="save">
                                            Salva
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-btn data-cy="" small color="red" dark class="mb-2" @click="emettiPolizza">
                                            Passa in attesa di emissione
                                        </v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>

                        </section>
                    </div>
                </div>
            </v-tab-item>

            <v-tab-item>
                <quotazione-diretta-upload :quotazione="quotazione"
                    :veicoli_storici="veicoli_storici"></quotazione-diretta-upload>
            </v-tab-item>
        </v-tabs>
        <div :class="{ 'loading-overlay': loading }" style="z-index: 10000"></div>
    </v-container>
</template>

<script>
import DatePicker from "../ui/form/teo-date-picker.vue";

import AutocompleteVue from "../ui/Autocomplete.vue";
import InvalidFeedback from "../ui/InvalidFeedback.vue";
// import axios from 'axios';
import { errorCallback, Api } from "@/http_tools/axiosModule";

import AutoStoricheTable from "./AutoStoricheTable.vue";
import QuotazioneDirettaUpload from "./QuotazioneDirettaUpload.vue";

export default {
    data: () => ({
        forceCheck: null,
        alert: {},
        veicoli_storici: [],
        veicoli_moderni: [],
        json: null,
        customer: null,
        loading: false,
        quotazione: {},
        errors: [],
        activePicker: null,
        response: null,
        required: [
            function (value) {
                return !!value || "Campo Obbligatorio.";
            },
        ],
        selectedCustomer: null,
        searchQuery: "",
        customers: [],
    }),
    components: {
        "teo-autocomplete": AutocompleteVue,
        "invalid-feedback": InvalidFeedback,
        "date-picker": DatePicker,
        "auto-storiche-table": AutoStoricheTable,
        "quotazione-diretta-upload": QuotazioneDirettaUpload
    },
    created() {
        this.fetchStatiQuotazione()
        this.id = this.$route.params.id
        this.fetchVeicoli(this.id)
        this.fetchQuotazione(this.id)
    },
    methods: {
        salvaVeicoliStorici(new_value) {
            this.veicoli_storici = new_value
            //console.log(new_value)
        },
        async saveQuotation() {
            await Api.put(process.env.VUE_APP_API_URL + "/api/v1/goold/quotations/" + this.quotazione.id, this.quotazione)
                .then((response) => {
                    this.response = response
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.response = error
                        this.errors = error.response.data.errors;
                        this.alert.type = "error"
                        this.alert.message = error.response.data.message
                        this.loading = false
                    }
                });
        },
        async saveVehicles() {
            this.loading = true;
            const payload = {
                historic: this.veicoli_storici,
                modern: this.veicoli_moderni
            };
            await Api.post(process.env.VUE_APP_API_URL + "/api/v1/goold/quotations/" + this.quotazione.id + "/vehicles", payload)
                .then((response) => {
                    //this.alert.type = "success"

                    return response
                    //this.alert.message = response.data.message
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                        this.alert.type = "error"
                        this.alert.message = error.response.data.message
                    }
                });
        },
        async save() {
            this.loading = true
            await this.saveQuotation()
            if (this.response.data.code == 201) {
                await this.saveVehicles()
                    .then(() => {
                        this.alert.type = "success"
                        this.alert.message = "Quotazione salvata con successo"

                    })
                    .catch((error) => {
                        if (error.response) {
                            errorCallback(error);
                            this.errors = error.response.data.errors;
                            this.alert.type = "error"
                            this.alert.message = error.response.data.message
                        }
                    });
            }
            this.loading = false
        },

        async fetchQuotazione(id) {
            await Api.get(process.env.VUE_APP_API_URL + "/api/v1/goold/quotations/" + id + "/edit")
                .then((response) => {
                    this.quotazione = response.data.data
                    if (this.quotazione.status_id != 12) {
                        this.$router.push({
                            name: "quotazione_edit",
                        });
                    }
                    this.aggiungiVeicoliModerni()
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        async fetchStatiQuotazione() {
            await Api.get(process.env.VUE_APP_API_URL + "/api/v1/goold/states/quotation")
                .then((response) => {
                    this.stati_quotazione = response.data;

                })
                .catch((error) => {
                    errorCallback(error);
                    this.errors = error.response.data.errors;
                });
        },
        async fetchVeicoli(id) {
            await Api.get(process.env.VUE_APP_API_URL + "/api/v1/goold/quotations/" + id + "/vehicles")
                .then((response) => {
                    this.veicoli_storici = response.data.data.historic
                    this.veicoli_moderni = response.data.data.modern
                    this.aggiungiVeicoliModerni()
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
        },
        aggiungiVeicoliModerni() {
            console.log(this.veicoli_moderni)
            console.log(this.quotazione.daily_use)
            if (this.veicoli_moderni.length < this.quotazione.daily_use) {
                for (let i = this.veicoli_moderni.length; i < this.quotazione.daily_use; i++) {
                    let veicolo = { brand: "", model: "", year: "", plate_number: "", relation: "" };
                    this.veicoli_moderni.push(veicolo);
                }
            } else if (this.veicoli_moderni.length > this.quotazione.daily_use) {
                this.veicoli_moderni.splice(-(this.veicoli_moderni.length - this.quotazione.daily_use), this.veicoli_moderni.length - this.quotazione.daily_use)
            }
        },

        async emettiPolizza() {
            var apiURL = process.env.VUE_APP_API_URL + "/api/v1/goold/quotations/" + this.quotazione.id + "/emit";
            await Api.get(apiURL)
                .then((response) => {
                    this.$router.push({
                        name: "quotazione_edit",
                        params: {
                            message: response.data.message,
                            type: "success"
                        },
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        errorCallback(error);
                        this.errors = error.response.data.errors;
                        this.alert.type = "error"
                        this.alert.message = error.response.data.message
                    }
                });
        }

    },
    filters: {
        pretty: function (value) {
            return JSON.stringify(JSON.parse(value), null, 2);
        },
    },

    computed: {
        showCambiaStato() {
            let _return = true;

            if (!this.can("cambiare_stato_quotazione")) _return = false;
            return _return;
        },
        mode() {
            return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
        },

    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },


};
</script>

<style>
.v-slider__thumb {
    width: 36px;
    height: 36px;
    left: -18px;
}

.v-slider__thumb:before {
    left: 0px;
    top: 0px;
}

.v-slider--horizontal .v-slider__track-container {
    height: 8px;
}
</style>
