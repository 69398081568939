<template>
    <section class="form_emissione_veicolo">
        <!-- {{ veicolo }} -->
        <v-row v-if="veicolo">
            <v-col>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title @click="assegnaAventeDirittoContraente()"
                            >Avente Diritto</v-list-item-title
                        >
                        <v-text-field label="Nome" v-model="veicolo.avente_diritto_nome"></v-text-field>
                        <v-text-field label="Cognome" v-model="veicolo.avente_diritto_cognome"></v-text-field>
                        <v-text-field
                            label="Codice Fiscale / Piva"
                            :hint="veicolo.avente_diritto_codice_fiscale_iva"
                            persistent-hint
                            v-model="veicolo.avente_diritto_codice_fiscale_iva"
                        ></v-text-field>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        {{ veicolo.alert }}
                    </v-list-item-content>
                </v-list-item>
            </v-col>
            <v-col>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex" @click="assegnaAventeDirittoSivi(item, quotazione)">
                            <h5 color="primary">
                                <v-btn color="primary" icon small>
                                    <v-icon>mdi-cog</v-icon>
                                    Sivi
                                </v-btn>
                            </h5>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <b>sivi_tax_code</b>
                            <v-chip class="ma-2">
                                {{ veicolo.sivi_tax_code ? veicolo.sivi_tax_code : "assente" }}
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content three-line>
                        <b color="primary">ContractDate</b>
                        <v-chip>
                            {{ veicolo.atrc_contract_expiration_date }}
                        </v-chip>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item three-line>
                    <v-list-item-content>
                        <v-list-item-title color="primary" @click="assegnaAventeDirittoAtrc(item, quotazione)">
                            <v-btn link small text color="primary" dark> usa <b>Atrc</b> </v-btn>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <b color="primary">BeneficiaryTaxCode</b>
                            <v-chip class="ma-2">
                                {{ veicolo.atrc_beneficiary_tax_code ? veicolo.atrc_beneficiary_tax_code : "assente" }}
                            </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <b color="primary">BeneficiaryFirstName</b>
                            <v-chip class="ma-2">
                                {{
                                    veicolo.atrc_beneficiary_first_name
                                        ? veicolo.atrc_beneficiary_first_name
                                        : "assente"
                                }}
                            </v-chip>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <b color="primary">BeneficiaryLastName</b>
                            <v-chip class="ma-2">
                                {{
                                    veicolo.atrc_beneficiary_last_name ? veicolo.atrc_beneficiary_last_name : "assente"
                                }}
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>

            <v-col>
            <v-list-item>
                <v-list-item-content>
                                  <v-checkbox

                                    :label="veicolo.classe_18 ? `Il Veicolo E' in classe 18°`: `Il Veicolo NON è in classe 18°` "

                                    data-cy="data_table__veicolo_storico_checkbox_classe_18_item.furto"
                                    v-model="veicolo.classe_18"
                                  ></v-checkbox
>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-select
                            label="Tipologia di veicolo"
                            :items="tipologie_ania"
                            item-text="nome"
                            item-value="codice"
                            :data-cy="'veicolo.tipologia_ania' + veicolo.marca"
                            v-model="veicolo.tipologia_ania"
                        >
                        </v-select>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="veicolo.tipologia_ania == 'C'">
                    <v-list-item-content>
                        <v-list-item-subtitle>
                            <b @click="assegnaQuintaliDaSivi(item)"
                                >sivi vehicle weight {{ veicolo.sivi_vehicle_weight }}</b
                            >
                        </v-list-item-subtitle>
                        <v-text-field type="number" label="Inserire i quintali" v-model="veicolo.quintali">
                        </v-text-field>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item-content>
                    <v-list-item>
                         <!-- <v-checkbox v-model="veicolo.furto" label="includere furto"></v-checkbox> -->
                        <v-text-field label="Targa" v-model="veicolo.targa"></v-text-field>
                        <v-select
                            label="Tipo di targa"
                            :items="tipologie_targa"
                            color="primary"
                            item-text="nome"
                            item-value="codice"
                            v-model="veicolo.tipologia_targa"
                        >
                        </v-select>
                    </v-list-item>
                    <v-list-item class="d-flex align-start">
                        <v-btn dense small color="orange" dark
                            class="mr-3" 
                            @click="updateQuotazionePerEmissione"
                            >
                            salva
                        </v-btn>
                        <div>
                            <v-btn dense small 
                            :disabled="veicolo && veicolo?.targa && veicolo?.targa?.trim().length == 0"
                            @click="updateAniaData()"
                            >
                            <v-icon> mdi-refresh </v-icon>Aggiorna Ania
                        </v-btn>
                                        <br>
                            <div class="text-red pt-2" v-if="veicolo && veicolo?.targa?.trim()?.length == 0">
                                Devi compilare  la targa per aggiornare i dati ania
                            </div>
                    </div>
                    </v-list-item> 
                    <v-list-item> Tipo di targa teo {{ veicolo.teo_tipologia_targa }} </v-list-item>
                    <v-list-item-subtitle>
                        <b>Data immatricolazione: {{ veicolo.data_immatricolazione }}</b>
                    </v-list-item-subtitle>
                    <v-list-item>
                        <date-picker
                            v-model="veicolo.data_immatricolazione"
                            value-type="YYYY-MM-DD"
                            format="DD/MM/YYYY"
                        ></date-picker>
                    </v-list-item>
                </v-list-item-content>
            </v-col>
        </v-row>
        <v-row>
                        <v-col>
                <v-list-item-content>
                    <v-list-item-title>Note</v-list-item-title>
                    <v-list-item>
                        <v-textarea
                            name="veicolo.comunicazioni_note"
                            label="Comunicazioni"
                            v-model="veicolo.note_veicolo"
                            hint="Scrivi le note per comunicare  a verti errori ania"
                        ></v-textarea>
                    </v-list-item>
                </v-list-item-content>
            </v-col>
        </v-row>
    </section>
</template>
<script>
import { tipologieAnia, tipologieTarga } from "@/enum/Verti/Tipologie";
import DatePicker from "../ui/form/teo-date-picker.vue";
import { assegnaAventeDirittoContraente, updateAniaData } from "@/services/AniaDataService";
// import IMask from 'imask';

// import AutocompleteVue from "../ui/Autocomplete.vue";
// import InvalidFeedback from "../ui/InvalidFeedback.vue";
// // import axios from 'axios';
// import {errorCallback, Api} from "@/http_tools/axiosModule";
// import {Api} from "@/http_tools/axiosModule";

export default {
    name: "form-emissione-veicolo",
    components: {
        // "teo-autocomplete": AutocompleteVue,
        // "invalid-feedback": InvalidFeedback,
        "date-picker": DatePicker,
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: function() {
            return {};
            }
        },
        quotazione:{
            type: Object,
            required: true,
        }
        // ,
        // veicolo_attribute :{
        //     type: Object,
        //     required:false
        // }
    },
    mounted() {
       
      setTimeout(() => {
        this.veicolo = Object.assign({}, this.value);
    }, 500); 

    },
    data() {
        return {
            veicolo: {},
            tipologie_ania: tipologieAnia,
            tipologie_targa: tipologieTarga,
        };
    },

    methods: {
        /* ------------------------------------------------------------------
            AVENTE DIRITTO
        -------------------------------------------------------------------- */
        assegnaAventeDirittoSivi(veicolo, quotazione) {
            console.log("assegnaAventeDirittoSivi", veicolo, quotazione);
            if (veicolo.sivi_tax_code) {
                if (veicolo.sivi_tax_code == quotazione.cfisc) {
                    veicolo.avente_diritto_cognome = quotazione.cognome;
                    veicolo.avente_diritto_nome = quotazione.nome;
                    veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
                    veicolo.active_sivi_tax_code = true;
                    veicolo.alert = "il codice fiscale corrisponde a quello del contraente";
                    return veicolo;
                } else {
                    veicolo.avente_diritto_cognome = "";
                    veicolo.avente_diritto_nome = "";
                    veicolo.avente_diritto_codice_fiscale_iva = veicolo.sivi_tax_code;
                    veicolo.alert = "il codice fiscale indicato dal SIVI non corrisponde a quello del contraente";
                    return veicolo;
                }
            } else {
                veicolo.alert = "non sono presenti informazioni sul SIVI";
            }
        },
        assegnaAventeDirittoAtrc(veicolo, quotazione) {
            if (veicolo.atrc_beneficiary_tax_code) {
                if (veicolo.atrc_beneficiary_tax_code == quotazione.cfisc) {
                    veicolo.avente_diritto_cognome = quotazione.cognome;
                    veicolo.avente_diritto_nome = quotazione.nome;
                    veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
                    veicolo.active_atrc_beneficiary_tax_code = true;
                    veicolo.alert =
                        "il codice fiscale dell' avente diritto coincide con il contraente indicato dal sivi";
                    return veicolo;
                } else {
                    veicolo.avente_diritto_cognome = veicolo.atrc_beneficiary_last_name;
                    veicolo.avente_diritto_nome = veicolo.atrc_beneficiary_first_name;
                    veicolo.avente_diritto_codice_fiscale_iva = veicolo.atrc_beneficiary_tax_code;
                    veicolo.alert =
                        "il codice fiscale dell' avente diritto è stato preso dall'attestato di rischio e non coincide con il contraente";
                    return veicolo;
                }
            } else {
                veicolo.alert = "non sono presenti informazioni sul ATRC (attestato di rischio)";
            }
        },
        assegnaAventeDirittoContraente() {

            const _veicolo = assegnaAventeDirittoContraente(this.veicolo,this.quotazione)
            // veicolo.avente_diritto_cognome = quotazione.cognome;
            // veicolo.avente_diritto_nome = quotazione.nome;
            // veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
            // veicolo.active_teo_cliente = true;
            // // veicolo.alert.aventeDiritto = = "
            // veicolo.alert = "Il contraente è l'avente diritto";
            return _veicolo
        },
        assegnaQuintaliDaSivi(veicolo) {
            // alert(veicolo.sivi_vehicle_weight);
            /**@see laravel backend "quintals" => $veicolo->tipologia_ania == "C" ? $veicolo->sivi_vehicle_weight : null */
            veicolo.alert = "Quintali assegnati dal sivi";
            veicolo.quintali = veicolo.sivi_vehicle_weight;
            // veicolo.quintali = 100;
        },

        assegnaQuintaliDaLibretto() {
            // veicolo.item.sivi_vehicle_weight
        },
        updateQuotazionePerEmissione() {},
        updateAniaData() {
            updateAniaData(this.veicolo)
        },
    },
    watch: {
        veicolo(newVal) {
            this.$emit("input", newVal);
            //alert(JSON.stringify(newVal));
        },
    },

    computed: {},

};
</script>

<style scoped></style>
