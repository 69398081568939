<template>
    <v-row>
        <v-col cols="8" id="form-veicolo">
            <v-alert text type="error" v-if="this.errors">
                {{ this.errors }}
            </v-alert>
            <v-row id="data-decorrenza-quotazione-spese-competenze">
                <v-col id="data-decorrenza">

<!-- {{ storage?.appendice?.data_effetto || appendice?.data_effetto     }} -->
Data di decorrenza esclusione
                            <date-picker label="Data di decorrenza esclusione" 
                                 v-model="appendice.data_effetto" 
                                 value-type="YYYY-MM-DD" 
                                 format="DD/MM/YYYY"
                                ></date-picker>

                    <div class="text-danger" v-if="!appendice?.data_effetto" >
                        data di decorrenza obbligatoria
                    </div>
                </v-col>
                <v-col id="appendice-importo-spese-emissione-competenze">
                    <!-- Modificabili solo da Admin -->
                    <v-text-field 
                        label="Appendice Verti" 
                        type="number" v-model="premio_verti"
                        :disabled="!this.can('conferma_quotazione')">
                    </v-text-field>
                    
                    <v-text-field 
                        label="Spese di emissione" 
                        type="number" 
                        v-model="spese_emissione"
                        :disabled="!this.can('conferma_quotazione')"></v-text-field>
                    
                    <!-- Modificabile da tutti -->
                    <v-text-field 
                        label="Competenze" 
                        type="number" 
                        v-model="competenze">
                    </v-text-field>

                </v-col>

            </v-row>
            <veicolo-storico-select :auto_storica_attribute="auto_storica" v-model="auto_storica" />
            <!-- <div v-if="true"> -->

                <form-emissione-veicolo 
                    :quotazione="quotazione"
                    v-model="veicolo_appendice" 
                />
            <!-- </div> -->

            
            <!-- :veicolo_attribute="veicolo"  -->
        </v-col>
        <v-col cols="4" id="preview-emissione">
            <v-card>
                <v-card-title class="loading">
                    Emissione
                    <span v-if="auto_storica" class="pl-3">Veicolo {{ this.auto_storica.colore }}</span>

                </v-card-title>
                <v-row align="center" class="px-3 pt-0 pb-3">
                    <p v-if="premio_annuale">Nuovo premio annuale teorico: {{ this.premio_annuale }}</p>
                    <div class="text-h2" >
                        € <span v-if="appendice_totale">{{ appendice_totale }}</span>
                        <span v-if="!appendice_totale" class="text-gray">00.00</span>
                    </div>
                </v-row>
                <v-card-actions v-if="roleIs('super_admin') || (auto_storica.colore !== 'rosso') && can('emettere_polizza_auto_storica')">
                    <v-btn color="red" class="elevation-0" 
                    :disabled="!auto_storica?.modello"
                     dark @click="emissioneAppendiceInclusione">
                        EMISSIONE APPENDICE INCLUSIONE
                    </v-btn>
                </v-card-actions>
                   <!-- roleIs(role) {
      //console.log(JSON.parse(window.sessionStorage["roles"]).includes(role));
      return JSON.parse(window.sessionStorage["roles"]).includes(role);
    }, -->
                    <v-btn v-if="!roleIs('super_admin') && auto_storica.colore == 'rosso'" color="red" text dark>
                        IN ATTESA DI DEROGA
                    </v-btn>
           
               
                <v-card-actions>
                    <v-btn  color="red" 
                            :disabled="!emissioneAppendiceInclusione" 
                            dark
                            @click="saveAppendice">
                            SALVA
                        </v-btn>
                </v-card-actions>
                <v-checkbox v-model="debug" label="Debug"></v-checkbox>
            </v-card>

            <v-card id="errori">
                <v-card-title>Errori</v-card-title>
                <pre>
                    {{ errors }}
                    <b>appendiceDiInclusioneRequest</b> 
                    {{ appendiceDiInclusioneRequest }}
                    <b>storage</b>
                    {{ storage.appendice }}
                </pre>
            </v-card>
        </v-col>

    </v-row>
</template>

<script>
import VeicoloStoricoSelect from "./VeicoloStoricoSelect.vue";
import FormEmissioneVeicolo from "./FormEmissioneVeicolo.vue";
// import DatePicker from "../ui/form/teo-date-picker.vue";
  import DatePicker from 'vue2-datepicker';

import { Api } from "@/http_tools/axiosModule";
import { readFromRemoteStorage, saveToRemoteStorage } from "@/services/StorageModule.js";
import { getTodaySQLDate } from "@/services/DateUtils.js";
// import { AutoStoricaService } from "@/services/AutoStoricaService.js";
export default {
    name: "appendice-inclusione-form",
    components: {
        "veicolo-storico-select": VeicoloStoricoSelect,
        "form-emissione-veicolo": FormEmissioneVeicolo,
        DatePicker,
    },
    // mixing: [AutoStoricaService],
    props: ["quotazione"],
    data() {
        return {
             // Milliseconds
            label: "DateTime",
            focus: false,
            storage:{},
            appendice: {
                 data_effetto: getTodaySQLDate()
            },
            // veicolo: function() {return {"a":3}},
            veicolo_appendice: {},
            auto_storica: {},
            debug: false,
            errors: null,
            response: {},
            loading: false,
            premio_verti: 0,
            spese_emissione: 15,
            competenze: 0,
            premio_annuale: null
        };
    },
    async mounted() {

        getTodaySQLDate
        const storage = await this.getStorage()
        // alert(JSON.stringify(storage));
        // console.log("storage --> da await",storage)
        if(storage && Object.keys(storage).length !== 0){

            /* eslint-disable */
            this.competenze = storage?.appendice?.competenze ?? 0
            this.premio_verti = storage?.appendice?.importo ?? 0
            
            this.auto_storica =  storage.auto_storica
            
            this.spese_emissione = storage?.appendice?.spese_emissione || 15
            // console.log("storage",storage.veicolo)
            this.veicolo_appendice = storage?.veicolo
            this.appendice = storage?.appendice
            this.storage = storage
        }
    },
    computed: {
        appendice_totale() {
            // this.competenze = undefined
            return  (Number(this.competenze) ?? 0) 
                    + (Number(this.spese_emissione) ?? 0) 
                    + (Number(this.premio_verti) ?? 0)  
        }, // competenze + premio_verti + competenze
        storageKey(){
            return `inclusione-${this.$route.params.id}`
        },
        appendiceDiInclusioneRequest () {
            const request = {
                auto_storica: this.auto_storica,
                veicolo: {  
                            ...{
                                "quotazione_id":this.$route.params.id,
                                "auto_storica_id":this.auto_storica?.id ?? null,
                                "cfisc":"todo",
                                "tipologia":"S",
                                "classe_18":false,
                            },
                            ...this.veicolo_appendice

                         },
                appendice: {
                            ...this.appendice,
                            ...{
                                "quotazione_id":this.$route.params.id,
                                "operazione" : "inclusione",
                                "competenze":this.competenze,
                                "spese_emissione":this.spese_emissione,
                                "appendice_totale" : this.appendice_totale,
                                "importo" : this.premio_verti
                                }
                           }
            }
            
            return request;

        },
    },
    methods: {
            roleIs(role) {
                //console.log(JSON.parse(window.sessionStorage["roles"]).includes(role));
                return JSON.parse(window.sessionStorage["roles"]).includes(role);
            },
        async emissioneAppendiceInclusione() {
            // this.ottieniDataSQL();
            this.loading = true
            await Api.post(
                process.env.VUE_APP_API_URL + "/api/emissione_appendice_inclusione_polizza_autostoriche_verti",
                this.appendiceDiInclusioneRequest
            )
                .then((response) => {
                    this.response = response.data;
                    this.errors = null;
                    this.loading = false
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        this.loading = false;
                        // this.response = response.data;
                        // errorCallback(error);
                        this.errors = error.response.data.errors;
                        this.response = "";
                        console.log(this.errors)
                    }
                });
        },

        async calcoloInclusione() {
            if (this?.appendice && this?.appendice?.data_effetto != null && this?.auto_storica?.id != null) {
                this.loading = true
                const payload = {
                    date: this.appendice.data_effetto,
                    historic: this.auto_storica
                };
                await Api.post(
                    process.env.VUE_APP_API_URL + "/api/v1/goold/quotations/inclusion/calculate/" + this.quotazione.id,
                    payload
                ).then((response) => {
                    console.log(response.data.data.premio_verti)
                    this.premio_verti = response.data.data.premio_verti
                    this.premio_annuale = response.data.data.nuovo_premio_annuale
             

                    this.calcoloImporto()
                    this.loading = false
                }).catch((error) => {
                    if (error.response) {
                        console.log("error", error.response);
                        this.loading = false;
                        // errorCallback(error);
                        this.errors = error.response.data.errors;
                    }
                });
            }
        },

        async getStorage(){

                const storage = await readFromRemoteStorage(this.storageKey)
                console.log("ottengo storage",storage);
                await new Promise(resolve => setTimeout(resolve, 100));
                return storage
        },
        async saveAppendice(){
            console.log(this.storageKey,this.appendiceDiInclusioneRequest)
            if(this.appendiceDiInclusioneRequest){
                this.storage = this.appendiceDiInclusioneRequest;
                // saveToLocalStorage(this.storageKey, this.appendiceDiInclusioneRequest);
               await saveToRemoteStorage(this.storageKey,this.appendiceDiInclusioneRequest);
            }
        }
    },
    watch: {
        auto_storica(newVal) {
            if (newVal != null){
                this.calcoloInclusione()
            }
        },
        appendice : {
                    handler(newVal) {
                        if (newVal != null) {
                            this.calcoloInclusione()
                        }
                    },
                    deep: true
        }
    },

};
</script>

<style scoped></style>
