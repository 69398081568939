import {Api,errorCallback} from "../../http_tools/axiosModule";

const state = {
    autocomplete_settore : []
}
const getters = {
    // autocomplete_settore(state){
    //     return state.autocomplete_settore
    // }
}
const actions = {

    action_autocomplete_settore : ({ commit }) => {
        Api.get(process.env.VUE_APP_API_URL + `/api/autocomplete/settore`)
        .then((response)=>{
        
          const data = response.data.data 
 
          commit('setAutocompleteSettore',data)
        }).catch((errors)=>errorCallback(errors))
      }
}
const mutations = {
    setAutocompleteSettore : (state,data) => state.autocomplete_settore = data
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

