<template>
    <section class="veicolo_storico_select">
        <v-card   :loading="loading">
            <v-card-title>Veicolo Storico

            <span  class="box-modello pa-3">
                <span class="box-modello-wrap" v-if="selectedMarca">
                    <!-- {{ selectedMarca }} -->
                    <span class="pr-3" v-if="selectedMarca">{{ selectedMarca }}</span>
                    <span class="pr-3" v-if="auto_storica">modello {{ auto_storica?.modello }} del {{ auto_storica?.anno }}</span>
                    <span class="pr-3" v-if="auto_storica">colore {{ auto_storica?.colore }}</span>
                </span>
            </span>


            </v-card-title>
        
            <v-form>
                <v-container>
                    <v-autocomplete 
                        autocomplete="new-password" 
                        data-cy="selectedMarca" 
                        v-model="selectedMarca"
                        :items="marche"
                        item-text="marca" 
                        
                        label="Seleziona la marca" 
                        @change="auto_storica = null;searchMarca()">
                    </v-autocomplete>
                    <!-- @change="" -->
                    <v-autocomplete 
                        autocomplete="new-password" 
                        data-cy="auto_storica" 
                        v-model="auto_storica"
                        :items="modelli" 
                        item-value="id"
                        item-text="nome" 
                        return-object 
                        label="Seleziona il modello">
                    </v-autocomplete>
                </v-container>
            </v-form>

        </v-card>
    </section>
</template>

<script>
// import DatePicker from "../ui/form/teo-date-picker.vue";

// import AutocompleteVue from "../ui/Autocomplete.vue";
// import InvalidFeedback from "../ui/InvalidFeedback.vue";
// // import axios from 'axios';
// import {errorCallback, Api} from "@/http_tools/axiosModule";
import { Api } from "@/http_tools/axiosModule";
import { AutoStoricaService } from "@/services/AutoStoricaService.js";

export default {
    name: "veicolo-storico-select",
        props: {
        auto_storica_attribute: {
            type: Object,
            required: false,
        }
        
    },
    mixing: [AutoStoricaService],
    data() {
        return {
            marche: [],
            modelli: [],
            dialog: false,
            auto_storica: {},
            selectedMarca: {},
            loading:false
        };
    },
    async mounted() {
        await this.fetchMarche();
        //console.log("mounted",this.auto_storica_attribute,this.auto_storica_attribute.marca)
        if(this.auto_storica_attribute){
            this.selectedMarca = this.auto_storica_attribute.marca
            await this.searchMarca()
            this.auto_storica = this.auto_storica_attribute
        }
        // console.log("FETCT")
    },
    methods: {
        async searchMarca() {
            this.loading = true;
            const response = await Api.get(
                process.env.VUE_APP_API_URL + "/api/autostoriche/search?filter[marca]=" + this.selectedMarca
            );
            
            this.modelli = response.data;
            this.loading = false;
            return response.data
        },
        async fetchMarche() {
            
            const response = await Api.get(process.env.VUE_APP_API_URL + "/api/autostoriche/marche");
            this.marche = response.data;
              
        },
        aggiungiVeicoloStorico() {
            this.$emit("input", this.auto_storica);
            this.dialog = false;
        },
        closeDialog() {
            this.selectedMarca = null;
            this.auto_storica = null;
            this.loading = false;
            this.$emit("input", this.auto_storica);
            this.dialog = false;
        },
    },
    watch: {
        auto_storica: function (new_value) {
            console.log(new_value);
            
            this.$emit("input", new_value);
        },
        selectedMarca: function () {
        //    this.auto_storica = null
        },
    },

};
</script>

<style scoped></style>
