<template>
  <v-container>
    <div class="DataTableSpatieQueryBuilderTest">
      ciccio <h1>ciccio</h1>
    </div>
  </v-container>
  </template>
  
<script>
export default {
  name: 'DataTableSpatieQueryBuilderTest',
 
  data() {
    return {
      // Add any component-specific data properties here
    };
  }
};
</script>

<style scoped>
.DataTableSpatieQueryBuilderTest {
  /* Add your component styles here */
  padding: 5rem;
  background-color: aqua;
}
</style>
