<template>
    <v-container class="w-100">
        <v-card class="mx-auto mb-5">
            <v-card-title>Ania</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="veicolo.plate_number"
                            label="Targa"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="veicolo.plate_number_type"
                            :items="tipologie_targa"
                            label="Tipologia Targa"
                            item-text="nome"
                            item-value="codice"
                            required
                        ></v-select>
                    </v-col>

                    <v-col>
                        <v-select
                            v-model="veicolo.ania_type"
                            :items="tipologie_ania"
                            label="Tipologia Ania"
                            item-text="nome"
                            item-value="codice"
                            required
                        ></v-select>

                    </v-col>
                    <v-col v-if="veicolo.ania_type == 'C'">
                        <v-text-field
                            v-model="veicolo.quintals"
                            label="Quintali"
                            required
                        ></v-text-field>
                    </v-col>

                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
                <v-btn
                    dense
                    small
                    color="orange"
                    dark
                    @click="updateAniaData(veicolo)"
                >Aggiorna Ania</v-btn>
            </v-card-actions>
        </v-card>
        <v-card class="mb-auto">
            <v-card-title class="pb-0">Avente diritto

                <v-spacer></v-spacer>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="adDaContraente()"
                        >
                            <v-icon
                                color="orange"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-account
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Contraente</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="adDaSivi()"
                        >
                            <v-icon
                                color="blue"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-alpha-s-box
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Sivi</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            @click="adDaAtrc()"
                        >
                            <v-icon
                                color="purple"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-alpha-a-box
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Atrc</span>
                </v-tooltip>
            </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="veicolo.entitled_first_name"
                            label="Nome"
                        ></v-text-field>
                    </v-col>

                    <v-col>
                        <v-text-field
                            v-model="veicolo.entitled_last_name"
                            label="Cognome"
                        ></v-text-field>

                    </v-col>

                    <v-col>
                        <v-text-field
                            v-model="veicolo.entitled_tax_code"
                            label="Codice Fiscale"
                        ></v-text-field>

                    </v-col>

                </v-row>
            </v-card-text>
            <v-card-title>Sivi
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col>

                        <b>sivi_tax_code</b>
                        <v-chip class="ma-2">
                            {{
                                veicolo.sivi_tax_code
                                    ? veicolo.sivi_tax_code
                                    : "assente"
                            }}
                        </v-chip>
                    </v-col>

                    <v-col>
                        <b>ContractDate</b>
                        <v-chip class="ma-2">
                            {{
                                veicolo.atrc_contract_expiration_date
                            }}
                        </v-chip>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-card-text>
            <v-card-title>Atrc
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>

                        <b>BeneficiaryTaxCode</b>

                        <v-chip class="ma-2">
                            {{
                                veicolo.atrc_beneficiary_tax_code
                                    ? veicolo.atrc_beneficiary_tax_code
                                    : "assente"
                            }}
                        </v-chip>
                    </v-col>

                    <v-col>
                        <b>BeneficiaryFirstName</b>
                        <v-chip class="ma-2">
                            {{
                                veicolo.atrc_beneficiary_first_name
                                    ?
                                    veicolo.atrc_beneficiary_first_name
                                    : "assente"
                            }}
                        </v-chip>
                    </v-col>
                    <v-col>
                        <b>BeneficiaryLastName</b>
                        <v-chip class="ma-2">
                            {{
                                veicolo.atrc_beneficiary_last_name
                                    ?
                                    veicolo.atrc_beneficiary_last_name
                                    : "assente"
                            }}
                        </v-chip>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-list-item-subtitle>
                            <b>Data immatricolazione:
                                {{ veicolo.registration_date }}</b>
                        </v-list-item-subtitle>
                        <v-list-item v-if="roleIs('super_admin')">
                            <date-picker
                                v-model="veicolo.registration_date"
                                value-type="YYYY-MM-DD"
                                format="DD/MM/YYYY"
                            ></date-picker>
                        </v-list-item>
                        <v-list-item two-line>
                            <v-radio-group
                                dense
                                v-model="veicolo.registration_date"
                                class="ma-0 pa-0"
                            >
                                <div v-if="this.veicolo.json != null">
                                    <v-radio
                                        v-if="JSON.parse(this.veicolo.json).ResponseSivi"
                                        :label="`sivi registration date ${JSON.parse(this.veicolo.json).ResponseSivi[0].registrationDate}`"
                                        :value="JSON.parse(this.veicolo.json).ResponseSivi[0].registrationDate"
                                    />
                                    <v-radio
                                        :label="`data auto ${veicolo.teo_registration_date}`"
                                        :value="veicolo.teo_registration_date"
                                    />
                                </div>
                            </v-radio-group>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-textarea
                            v-model="veicolo.notes"
                            label="Note"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { Api } from '@/http_tools/axiosModule';
import DatePicker from "../ui/form/teo-date-picker.vue";
import { tipologieAnia, tipologieTarga } from '@/enum/Verti/Tipologie';

export default {
    props: {
        value: Object,
        quotazione: Object,
    },
    created() {
        this.veicolo = this.value
    },
    components: {
        "date-picker": DatePicker,
    },
    data: () => ({
        ania: {},
        veicolo: {},
        tipologie_ania: tipologieAnia,
        tipologie_targa: tipologieTarga,
    }),
    methods: {
        assegnaAventeDirittoAtrc(veicolo, quotazione) {
            if (veicolo.atrc_beneficiary_tax_code) {
                if (veicolo.atrc_beneficiary_tax_code == quotazione.cfisc) {
                    veicolo.avente_diritto_cognome = quotazione.cognome;
                    veicolo.avente_diritto_nome = quotazione.nome;
                    veicolo.avente_diritto_codice_fiscale_iva = quotazione.cfisc;
                    veicolo.active_atrc_beneficiary_tax_code = true;
                    veicolo.alert =
                        "il codice fiscale dell' avente diritto coincide con il contraente indicato dal sivi";
                    return veicolo;
                } else {
                    veicolo.avente_diritto_cognome = veicolo.atrc_beneficiary_last_name;
                    veicolo.avente_diritto_nome = veicolo.atrc_beneficiary_first_name;
                    veicolo.avente_diritto_codice_fiscale_iva =
                        veicolo.atrc_beneficiary_tax_code;
                    veicolo.alert =
                        "il codice fiscale dell' avente diritto è stato preso dall'attestato di rischio e non coincide con il contraente";
                    return veicolo;
                }
            } else {
                veicolo.alert =
                    "non sono presenti informazioni sul ATRC (attestato di rischio)";
            }
        },
        adDaContraente() {
            //console.log(this.quotazione)
            this.veicolo.entitled_first_name = this.quotazione.first_name
            this.veicolo.entitled_last_name = this.quotazione.last_name
            this.veicolo.entitled_tax_code = this.quotazione.tax_code
            this.veicolo.tax_code = this.quotazione.tax_code
        },
        adDaSivi() {
            if (this.veicolo.sivi_tax_code) {
                if (this.veicolo.sivi_tax_code == this.quotazione.cfisc) {
                    this.veicolo.entitled_first_name = this.quotazione.first_name
                    this.veicolo.entitled_last_name = this.quotazione.last_name
                } else {
                    this.veicolo.entitled_first_name = ""
                    this.veicolo.entitled_last_name = ""
                }
                this.veicolo.entitled_tax_code = this.veicolo.sivi_tax_code
                this.veicolo.tax_code = this.veicolo.sivi_tax_code
            } else {
                console.log("non sono presenti informazioni sul SIVI")
            }
        },
        adDaAtrc() {
            if (this.veicolo.atrc_beneficiary_tax_code) {
                if (this.veicolo.atrc_beneficiary_tax_code == this.quotazione.cfisc) {
                    this.veicolo.entitled_first_name = this.quotazione.first_name
                    this.veicolo.entitled_last_name = this.quotazione.last_name
                } else {
                    this.veicolo.entitled_first_name = ""
                    this.veicolo.entitled_last_name = ""
                }
                this.veicolo.entitled_tax_code = this.veicolo.atrc_beneficiary_tax_code
            } else {
                console.log("non sono presenti informazioni sul ATRC")
            }
        },
        roleIs(role) {
            //console.log(JSON.parse(window.sessionStorage["roles"]).includes(role));
            return JSON.parse(window.sessionStorage["roles"]).includes(role);
        },
        async updateAniaData(veicolo) {
            this.loading = true;
            const response = await Api.post(
                process.env.VUE_APP_API_URL +
                `/api/veicolo_update_dati_ania/${veicolo.id}/${veicolo.plate_number}/${veicolo.ania_type}`
            );
            this.ania = response.data

            veicolo.json = JSON.stringify(response.data);
            veicolo.sivi_tax_code = response.data.ResponseSivi[0].taxCode
            veicolo.atrc_contract_expiration_date = response.data.ResponseAtrcMain[0].contractExpirationDate
            veicolo.atrc_beneficiary_first_name = response.data.ResponseAtrcMain[0].beneficiaryFirstName
            veicolo.atrc_beneficiary_last_name = response.data.ResponseAtrcMain[0].beneficiaryLastName
            veicolo.sivi_registration_date = response.data.ResponseSivi[0].registrationDate
            this.loading = false;
        },

        async updateVehicle(veicolo) {
            this.loading = true;
            console.log(veicolo)
            const payload = {
                vehicle: veicolo
            };
            await Api.put(process.env.VUE_APP_API_URL + "/api/v1/goold/vehicles/" + veicolo.id, payload)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    watch: {
        veicolo: {
            handler(new_value) {
                this.$emit("input", new_value);
            },
            deep: true
        }
    }

}
</script>
