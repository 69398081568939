<template>
  <section id="CompagniaForm" class="CompagniaForm" v-if="formData">
    <v-card class="px-3">
      <CardAction
        :sezione-attiva="$store.state.sezioneAttiva"
        v-model="formData.attivo"
        @on-annulla="annulla()"
        @on-aggiorna="aggiorna()"
        @on-aggiungi="aggiorna()"
        >
      </CardAction>

      <!-- prova tab qui -->

      <VTabs color="primary" v-model="tabs.compagnia" @change="changeTab()" slider-color="primary">
        <VTab ripple> Anagrafica </VTab>
        <!-- QUI -->
        <VTab ripple :disabled="mode == 'ADD_NEW_MODE'"> Prodotti </VTab>
        <VTabItem>
          <!-- FORM ANAGRAFICA -->
          <v-card-text>
            <section id="compagnia-app" class="row">
              <div id="RagionesocialeField" class="form-group col-md-3">
                <label for="ragioneSociale">Ragione Sociale:</label>
                <input
                  name="ragioneSociale"
                  v-model="formData.ragioneSociale"
                  :class="{ 'is-invalid': errors.ragioneSociale }"
                  type="text"
                  id="ragioneSociale"
                  class="form-control- form-control" />
                <invalid-feedback :errors="errors.ragioneSociale" />
              </div>

              <div id="TipocompagniaField" class="form-group col-md-3">
                <label for="tipoCompagnia">Tipo di Fornitore:</label>
                <select
                  id="tipoCompagnia"
                  name="tipoCompagnia"
                  v-model="formData.tipoCompagnia"
                  :class="{ 'is-invalid': errors.tipoCompagnia }"
                  class="form-control- form-control custom-select">
   
                  <option value="DIRETTO">Diretta</option>
                  <option value="FORNITORE">Fornitore</option>
                </select>
                <invalid-feedback :errors="errors.tipoCompagnia" />
              </div>

              <div id="ReferenteField" class="form-group col-md-3">
                <label for="referente">Referente:</label>
                <input
                  name="referente"
                  v-model="formData.referente"
                  :class="{ 'is-invalid': errors.referente }"
                  type="text"
                  id="referente"
                  class="form-control- form-control" />
                <invalid-feedback :errors="errors.referente" />
              </div>

              <section id="indirizzo__sede_legale" class="fieldset">
                <h3 class="h6 fieldset__title">Sede Legale</h3>
                <div class="fieldset__content row">
                  <div id="IndirizzoField" class="form-group col-md-5">
                    <label for="indirizzo">Indirizzo:</label>
                    <input
                      name="indirizzo"
                      v-model="formData.indirizzo"
                      :class="{ 'is-invalid': errors.indirizzo }"
                      type="text"
                      id="indirizzo"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.indirizzo" />
                  </div>
                  <div id="CivicoField" class="form-group col-md-1">
                    <label for="civico">Civico:</label>
                    <input
                      name="civico"
                      v-model="formData.civico"
                      :class="{ 'is-invalid': errors.civico }"
                      type="text"
                      id="civico"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.civico" />
                  </div>
                  <div id="CittaField" class="form-group col-md-3">
                    <label for="citta">Citta:</label>
                    <input
                      name="citta"
                      v-model="formData.citta"
                      :class="{ 'is-invalid': errors.citta }"
                      type="text"
                      id="citta"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.citta" />
                  </div>
                  <div id="CapField" class="form-group col-md-1">
                    <label for="cap">Cap:</label>
                    <input
                      name="cap"
                      v-model="formData.cap"
                      :class="{ 'is-invalid': errors.cap }"
                      type="text"
                      id="cap"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.cap" />
                  </div>
                  <div id="ProvField" class="form-group col-md-1">
                    <label for="prov">Prov:</label>
                    <input
                      name="prov"
                      v-model="formData.prov"
                      :class="{ 'is-invalid': errors.prov }"
                      type="text"
                      id="prov"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.prov" />
                  </div>
                </div>
              </section>

              <section id="contatti__email" class="fieldset">
                <h3 class="h6 fieldset__title">Email</h3>
                <div class="fieldset__content row">
                  <div
                    id="EmailamministrazioneField"
                    class="form-group col-md-3">
                    <label for="emailAmministrazione">Amministrazione:</label>

                    <input
                      name="emailAmministrazione"
                      v-model="formData.emailAmministrazione"
                      :class="{ 'is-invalid': errors.emailAmministrazione }"
                      type="email"
                      id="emailAmministrazione"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.emailAmministrazione" />
                  </div>

                  <div class="form-group col-md-3">
                    <label for="emailPerEstrattoConto">
                      Amministrazione (per estratto conto)
                    </label>

                    <input
                      name="emailPerEstrattoConto"
                      v-model="formData.emailPerEstrattoConto"
                      :class="{ 'is-invalid': errors.emailPerEstrattoConto }"
                      type="email"
                      id="emailPerEstrattoConto"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.emailPerEstrattoConto" />
                  </div>

                  <div class="form-group col-md-3">
                    <label
                      for="riferimentoAmministrativoFatturazioneElettronica">
                      Riferimento amministrativo fatturazione elettronica
                    </label>

                    <input
                      name="riferimentoAmministrativoFatturazioneElettronica"
                      v-model="
                        formData.riferimentoAmministrativoFatturazioneElettronica
                      "
                      :class="{
                        'is-invalid':
                          errors.riferimentoAmministrativoFatturazioneElettronica,
                      }"
                      type="email"
                      id="riferimentoAmministrativoFatturazioneElettronica"
                      class="form-control- form-control" />
                    <invalid-feedback
                      :errors="
                        errors.riferimentoAmministrativoFatturazioneElettronica
                      " />
                  </div>

                  <div id="EmailquotazioniField" class="form-group col-md-3">
                    <label for="emailQuotazioni">Quotazioni:</label>
                    <input
                      name="emailQuotazioni"
                      v-model="formData.emailQuotazioni"
                      :class="{ 'is-invalid': errors.emailQuotazioni }"
                      type="email"
                      id="emailQuotazioni"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.emailQuotazioni" />
                  </div>
                  <div id="EmailpecreclamiField" class="form-group col-md-3">
                    <label for="emailPECReclami">Reclami:</label>
                    <input
                      name="emailPECReclami"
                      v-model="formData.emailPECReclami"
                      :class="{ 'is-invalid': errors.emailPECReclami }"
                      type="email"
                      id="emailPECReclami"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.emailPECReclami" />
                  </div>

                  <div id="EmailField" class="form-group col-md-3">
                    <label for="email">Email:</label>
                    <input
                      name="email"
                      v-model="formData.email"
                      :class="{ 'is-invalid': errors.email }"
                      type="email"
                      id="email"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.email" />
                  </div>
                  <div id="PecField" class="form-group col-md-3">
                    <label for="pec">Pec:</label>
                    <input
                      name="pec"
                      v-model="formData.pec"
                      :class="{ 'is-invalid': errors.pec }"
                      type="email"
                      id="pec"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.pec" />
                  </div>
                </div>
              </section>

              <section id="contatti__telefono" class="fieldset">
                <h3 class="h6 fieldset__title">Telefono</h3>
                <div class="fieldset__content row">
                  <div id="TelField" class="form-group col-md-3">
                    <label for="te1">Telefono 1</label>
                    <input
                      name="tel"
                      v-model="formData.tel"
                      type="text"
                      id="tel"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.tel" />
                  </div>

                  <div id="CellField" class="form-group col-md-3">
                    <label for="cell">Cell:</label>
                    <input
                      name="cell"
                      v-model="formData.cell"
                      :class="{ 'is-invalid': errors.cell }"
                      type="text"
                      id="cell"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.cell" />
                  </div>
                </div>
              </section>

              <section id="pagamenti__fatturazione" class="fieldset">
                <h3 class="h6 fieldset__title">Pagamenti e Fatturazione</h3>
                <div class="fieldset__content row">
                  <div id="CodicedestinatarioField" class="form-group col-md-3">
                    <label for="codiceDestinatario">Codice Destinatario</label>

                    <input
                      name="codiceDestinatario"
                      v-model="formData.codiceDestinatario"
                      :class="{ 'is-invalid': errors.codiceDestinatario }"
                      type="text"
                      id="codiceDestinatario"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.codiceDestinatario" />
                  </div>

                  <div id="PivaField" class="form-group col-md-3">
                    <label for="piva">Partita Iva</label>
                    <input
                      name="piva"
                      v-model="formData.piva"
                      :class="{ 'is-invalid': errors.piva }"
                      type="text"
                      id="piva"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.piva" />
                  </div>

                  <div id="NumerocciaaField" class="form-group col-md-3">
                    <label for="numeroCCIAA">Numero: CCIAA</label>
                    <input
                      name="numeroCCIAA"
                      v-model="formData.numeroCCIAA"
                      :class="{ 'is-invalid': errors.numeroCCIAA }"
                      type="text"
                      id="numeroCCIAA"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.numeroCCIAA" />
                  </div>

                  <div id="NumeroreaField" class="form-group col-md-3">
                    <label for="numeroREA">Numero REA</label>
                    <input
                      name="numeroREA"
                      v-model="formData.numeroREA"
                      :class="{ 'is-invalid': errors.numeroREA }"
                      type="text"
                      id="numeroREA"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.numeroREA" />
                  </div>

                  <div id="PercentritenutaField" class="form-group col-md-3">
                    <label for="percentRitenuta">% Ritenuta </label>
                    <select
                      id="percentRitenuta"
                      name="percentRitenuta"
                      v-model="formData.percentRitenuta"
                      :class="{ 'is-invalid': errors.percentRitenuta }"
                      class="form-control custom-select">
                      <option :value="4.6">4,60 %</option>
                      <option :value="11.5">11,50 %</option>
                    </select>

                    <invalid-feedback :errors="errors.percentRitenuta" />
                  </div>

                  <div class="form-group col-lg-3 py-0 px-1 mb-3">
                    <label for="iscrizioneRUIData">Data Accordo</label>

                    <date-picker
                      id="#dataAccordo"
                      data-cy="dataAccordo"
                      v-model="formData.dataAccordo"
                      :class="{
                        'is-invalid': errors.dataAccordo,
                      }"
                      value-type="YYYY-MM-DD"
                      format="DD/MM/YYYY"></date-picker>

                    <invalid-feedback :errors="errors.dataAccordo" />
                  </div>

                  <div id="TipologiarimesseField" class="form-group col-md-3">
                    <label for="tipologiaRimesse">Tipologia Rimesse</label>
                    <select
                      id="tipologiaRimesse"
                      name="tipologiaRimesse"
                      v-model="formData.tipologiaRimesse"
                      :class="{ 'is-invalid': errors.tipologiaRimesse }"
                      class="form-control- form-control custom-select">
                      <option value="FINE_MESE">FINE MESE</option>
                      <option value="ENTRO_5">ENTRO_5</option>
                      <option value="ENTRO_10">ENTRO_10</option>
                      <option value="EMISSIONE">EMISSIONE</option>
                      <option value="DECADALE">DECADALE</option>
                      <option value="QUINDICINALE">QUINDICINALE</option>
                    </select>
                    <invalid-feedback :errors="errors.tipologiaRimesse" />
                  </div>
                </div>
              </section>
              <form-fieldset header="RUI">
                <div class="form-group col-lg-3 py-0 px-1 mb-3">
                  <label for="iscrizioneRUIData">Data Iscrizione:</label>

                  <date-picker
                    data-cy="iscrizioneRUIData"
                    v-model="formData.iscrizioneRUIData"
                    :class="{
                      'is-invalid': errors.iscrizioneRUIData,
                    }"
                    value-type="YYYY-MM-DD"
                    format="DD/MM/YYYY"></date-picker>

                  <invalid-feedback :errors="errors.iscrizioneRUIData" />
                </div>

                <div class="form-group col-lg-3 py-0 px-1 mb-3">
                  <label for="iscrizioneRUI">Numero Iscrizione RUI</label>
                  <input
                    v-model="formData.iscrizioneRUI"
                    :class="{ 'is-invalid': errors.iscrizioneRUI }"
                    type="text"
                    id="iscrizioneRUI"
                    class="form-control- form-control" />
                  <invalid-feedback :errors="errors.iscrizioneRUI" />
                </div>
              </form-fieldset>

              <section id="pagamenti__IBAN" class="fieldset">
                <h3 class="h6 fieldset__title">IBAN (conto premi)</h3>

                <div class="fieldset__content row">
                  <div id="IBANContoPremiField" class="form-group col-md-3">
                    <label for="IBANContoPremi">IBAN Conto Premi:</label>
                    <input
                      name="IBANContoPremi"
                      v-model="formData.IBANContoPremi"
                      :class="{ 'is-invalid': errors.IBANContoPremi }"
                      type="text"
                      id="IBANContoPremi"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.IBANContoPremi" />
                  </div>

                  <div id="ReferenteruoloField" class="form-group col-md-3">
                    <label for="referenteRuolo">Referente Ruolo</label>
                    <input
                      name="referenteRuolo"
                      v-model="formData.referenteRuolo"
                      :class="{ 'is-invalid': errors.referenteRuolo }"
                      type="text"
                      id="referenteRuolo"
                      class="form-control- form-control" />
                    <invalid-feedback :errors="errors.referenteRuolo" />
                  </div>
                </div>
              </section>

              <FormFieldset header="SIC ANIA">
                <div class="form-group col-md-3">
                  <label for="sicAnia">Sic ANIA</label>
                  <input
                    name="sicAnia"
                    v-model="formData.sicAnia"
                    :class="{ 'is-invalid': errors.sicAnia }"
                    type="text"
                    id="sicAnia"
                    class="form-control- form-control" />
                  <invalid-feedback :errors="errors.sicAnia" />
                </div>

                <div class="form-group col-md-3">
                  <label for="sicAniaPassword">SIC ANIA Password</label>
                  <input
                    name="sicAniaPassword"
                    v-model="formData.sicAniaPassword"
                    :class="{ 'is-invalid': errors.sicAniaPassword }"
                    type="text"
                    id="sicAniaPassword"
                    class="form-control- form-control" />
                  <invalid-feedback :errors="errors.sicAniaPassword" />
                </div>
                <div class="form-group col-md-3">
                  <label for="sicAniaCodiceEnte">SIC ANIA Codice Ente</label>
                  <input
                    name="sicAniaCodiceEnte"
                    v-model="formData.sicAniaCodiceEnte"
                    :class="{ 'is-invalid': errors.sicAniaCodiceEnte }"
                    type="text"
                    id="sicAniaCodiceEnte"
                    class="form-control- form-control" />
                  <invalid-feedback :errors="errors.sicAniaCodiceEnte" />
                </div>
              </FormFieldset>

              <div id="NoteField" class="form-group col-md-3">
                <label for="note">Note</label>
                <input
                  name="note"
                  v-model="formData.note"
                  :class="{ 'is-invalid': errors.note }"
                  type="text"
                  id="note"
                  class="form-control- form-control" />
                <invalid-feedback :errors="errors.note" />
              </div>

              <div id="IsbloccataField" class="form-group col-md-3">
                <div class="form-check">
                  <input
                    name="isBloccata"
                    v-model="formData.isBloccata"
                    :class="{ 'is-invalid': errors.isBloccata }"
                    type="hidden"
                    value="0"
                    class="form-check-input" />
                  <input
                    name="isBloccata"
                    v-model="formData.isBloccata"
                    :class="{ 'is-invalid': errors.isBloccata }"
                    type="checkbox"
                    value="1"
                    class="form-check-input" />
                  <label for="isBloccata" class="form-check-label"
                    >Isbloccata:</label
                  >
                </div>
              </div>
              <div id="IsesclusofogliocassaField" class="form-group col-md-3">
                <div class="form-check">
                  <input
                    name="isEsclusoFoglioCassa"
                    v-model="formData.isEsclusoFoglioCassa"
                    :class="{ 'is-invalid': errors.isEsclusoFoglioCassa }"
                    type="hidden"
                    value="0"
                    class="form-check-input" />
                  <input
                    name="isEsclusoFoglioCassa"
                    v-model="formData.isEsclusoFoglioCassa"
                    :class="{ 'is-invalid': errors.isEsclusoFoglioCassa }"
                    type="checkbox"
                    value="1"
                    class="form-check-input" />
                  <label for="isEsclusoFoglioCassa" class="form-check-label"
                    >Isesclusofogliocassa:</label
                  >
                </div>
              </div>

              <div id="Articolo117Field" class="form-group col-md-3">
                <div class="form-check">
                  <input
                    name="articolo117"
                    v-model="formData.articolo117"
                    :class="{ 'is-invalid': errors.articolo117 }"
                    type="hidden"
                    value="0"
                    class="form-check-input" />
                  <input
                    name="articolo117"
                    v-model="formData.articolo117"
                    :class="{ 'is-invalid': errors.articolo117 }"
                    type="checkbox"
                    value="1"
                    class="form-check-input" />
                  <label for="articolo117" class="form-check-label"
                    >Articolo117:</label
                  >
                </div>
              </div>
              <div id="SitoufficialeField" class="form-group col-md-3">
                <label for="sitoUfficiale">Sitoufficiale:</label>
                <input
                  name="sitoUfficiale"
                  v-model="formData.sitoUfficiale"
                  :class="{ 'is-invalid': errors.sitoUfficiale }"
                  type="text"
                  id="sitoUfficiale"
                  class="form-control- form-control" />
                <invalid-feedback :errors="errors.sitoUfficiale" />
              </div>
            </section>
          </v-card-text>
        </VTabItem>
        <VTabItem>
          <!-- INDEX PRODOTTI -->
          <tabella-prodotto-assicurativo-mandato :key="rerenderTabellaProdotti"></tabella-prodotto-assicurativo-mandato>
        </VTabItem>
      </VTabs>
    </v-card>
    <div :class="{ 'loading-overlay': loading }"></div>
  </section>
</template>
<script>
  /* eslint-disable */
  import { mapState } from "vuex";

  import InvalidFeedbackVue from "../ui/InvalidFeedback.vue";

  import DatePicker from "vue2-datepicker";
  import CardAction from "../ui/form/card-action.vue";
  import TableComponent from "../ui/TableComponent.vue";
  import ProdottoAssicurativoForm from "../ProdottoAssicurativoForm.vue";
  import FormFieldset from "../ui/form/form-fieldset.vue";
  import TabellaProdottoAssicurativoMandato from "./TabellaProdottoAssicurativoMandato.vue";

  export default {
    name: "compagnia-field",
    props: ["compagnia"],
    components: {
      "invalid-feedback": InvalidFeedbackVue,
      "date-picker": DatePicker,
      CardAction,
      TableComponent,
      ProdottoAssicurativoForm,
      FormFieldset,
      TabellaProdottoAssicurativoMandato,
    },
    data() {
      return {
        tabs: {
        
          compagnia:0
        },
        dialog_prodotto_assicurativo: false,
        dialog_prodotto_assicurativo_label: "",
      };
    },
    mounted() {
      if (this.mode == "EDIT_MODE") {
        this.$store.dispatch("ACTION_GET_COMPAGNIA", this.$route.params.id);
        // FIX Riportare a zero
        this.tabs.compagnia = 0;
        
      } else if (this.mode == "ADD_NEW_MODE") {
        this.$store.commit("setCompagniaNew");
        this.tabs.compagnia = 0;
      }
    },
    methods: {
      onAnnulla() {
        this.$store.dispatch("ACTION_ANNULLA_COMPAGNIA");
      },
      aggiorna() {
      
        this.$store.dispatch("ACTION_STORE_FORNITORE", this.formData);
      },
      onEdit(item) {
        console.log("onEdit", item);
      },
      onDelete(item) {
        console.log("onDelete", item);
      },
      onAddNewItemProdottoAssicurativo() {
        console.log("onAddNewItemProdottoAssicurativo");

        this.dialog_prodotto_assicurativo = true;
        this.dialog_prodotto_assicurativo_label = "Assegna Nuovo Prodotto";
        // Prepara modale prodotto assicurativo
        this.$store.dispatch(
          "ACTION_CREATE_PRODOTTO_ASSICURATIVO",
          this.$route.params.id
        );
      },
      onAnnullaProdottoAssicurativo() {
        this.dialog_prodotto_assicurativo = false;
      },
      changeTab(event) {
        console.log(this.tabs.compagnia)
      }
    },
    computed: {
      loading() {
        return this.$store.state.loading;
      },
      formData() {
        return this.$store.state.compagniaAttivo;
      },
      mode() {
        //QUI
        return !this.$route.params.id ? "ADD_NEW_MODE" : "EDIT_MODE";
      },
      errors() {
        return this.$store.state.formError || {};
      },
      rerenderTabellaProdotti(){
        return this.$store.state.rerenderTabellaProdotti
      }

    },
  };
</script>
