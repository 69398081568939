<template>
  <section id="TabellaProdottoAssicurativoMandato" class="model__form">
    <v-row id="NuovoMandato" class="pt-0 d-flex align-end">
      <v-col>
        <!-- resource="https://api.publicapis.org/entries" -->
        <!-- TODO: impostare visualizzazione del -->

        <div class="categoria_prodotto__add_new_form">
          <div class="mt-5 h6">Nuovo Prodotto</div>
          <v-row>
            <v-col cols="3">
              <div class="d-flex"> 
                <!-- DIALOG CONTRATTUALE -->
                <v-dialog
                  v-model="dialog_aggiungi_nuova_compagnia_contrattuale"
                  persistent
                  max-width="590px">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="mb-3" color="primary" v-bind="attrs" v-on="on">
                      <v-icon right color="primary"> mdi-plus-thick </v-icon>
                      Nuova Compagnia
                    </div>
                    <!-- <v-btn
                      class="p-2"
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      color="primary">
                 <v-icon right> mdi-cloud-upload </v-icon>
                      Extra small Button
                    </v-btn> -->
                    <!-- <card-text color="primary">Nuova Compagnia</card-text> -->
                  </template>
                  <v-card>
                    <v-card-title class="text">
                      Aggiungi Nuova Compagnia Contrattuale
                    </v-card-title>
                    <v-card-text>
                      <compagnia-contrattuale-form
                        v-model="
                          new_compagnia_contrattuale
                        "></compagnia-contrattuale-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        :loading="loading_aggiungi_nuova_compagnia_contrattuale"
                        @click="
                          storeNewCompagniaContrattuale(
                            new_compagnia_contrattuale
                          )
                        ">
                        conferma
                      </v-btn>
                      <v-btn
                        :disabled="
                          loading_aggiungi_nuova_compagnia_contrattuale
                        "
                        color="primary"
                        text
                        @click="resetNewCompagniaContrattuale()">
                        annulla
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <div class="d-flex">
                <v-autocomplete
                  ref="compagnia_contrattuale"
                  name="contrattuale"
                  label="Compagnia Contrattuale"
                  dense
                  clearable
                  color="primary"
                  v-model="new_categoria_prodotto.compagnia_contrattuale_id"
                  :search-input.sync="new_categoria_prodotto.search_input"
                  item-value="id"
                  item-text="nome"
                  @change="newCategoriaProdottoChange()"
                  :items="compagnia_contrattuale_autocomplete"
                  :error-messages="errors.compagnia_contrattuale_id"
                  :error="
                    errors.compagnia_contrattuale_id &&
                    errors.compagnia_contrattuale_id.length > 0
                  " />
              </div>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="categoria/prodotto"
                v-model.trim="new_categoria_prodotto.nome"
                dense
                color="primary"
                placeholder="nome nuova categoria"
                :error-messages="errors.nome"
                :error="errors.nome && errors.nome.length > 0" />
            </v-col>
            <v-col cols="1">
              <text-formatted
                label="provvigione attiva"
                v-model="
                  new_categoria_prodotto.mandato.percentuale_provvigione_attiva
                "
                dense
                color="primary"
                :error-messages="
                  errors['mandato.percentuale_provvigione_attiva']
                "
                :error="
                  errors['mandato.percentuale_provvigione_attiva'] &&
                  errors['mandato.percentuale_provvigione_attiva'].length > 0
                "
                min="0"
                max="100"
                suffix="%">
              </text-formatted>
              {{ errors["mandato.percentuale_provvigione_attiva"] }}
            </v-col>
            <v-col cols="1">
              <teo-autocomplete
                name="new_categoria_prodotto_ramo_id"
                v-model="new_categoria_prodotto.ramo_id"
                item-value="id"
                item-text="label"
                ref="ramo"
                dense
                clearable
                label="Ramo"
                resource="/api/autocomplete/ramo" />
            </v-col>
            <v-col cols="2">
              <date-picker
                label="data inizio mandato"
                data-cy="data_inizio_mandato"
                color="primary"
                v-model="new_categoria_prodotto.mandato.data_inizio_mandato"
                value-type="YYYY-MM-DD"
                format="DD/MM/YYYY">
              </date-picker>
            </v-col>

            <v-col cols="1">
              <teo-autocomplete
                v-model="new_categoria_prodotto.settore_id"
                item-value="id"
                item-text="label"
                ref="ramo"
                dense
                color="primary"
                label="Settore"
                resource="/api/autocomplete/settore" />
            </v-col>

            <v-col cols="1">
              <v-btn
                dense
                :disabled="
                  new_categoria_prodotto &&
                  new_categoria_prodotto.nome &&
                  new_categoria_prodotto.nome.length <= 0
                "
                color="primary"
                @click="addNewCategoriaProdottoRow()"
                >aggiungi
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- <v-col v-if="formData.mandatoAttivo"> -->
    </v-row>
    <!-- :expanded="expanded" -->

    <section v-if="categoria_prodotto.length > 0">
      <!-- DATA_TABLE -->
      <div class="mt-5 h6">Categorie inserite</div>

      <v-data-table
        :loading="loading"
        loading-text="sto caricando i dati"
        :headers="headers"
        :items="categoria_prodotto"
        show-expand
        :expanded.sync="expanded"
        >
        <!-- FILTRI -->
        <template
          v-for="(col, index) in filters"
          v-slot:[`header.${index}`]="{ header }">
          {{ header.text }}
          <v-menu :key="index" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  small
                  :color="filters[header.value].length ? 'red' : ''">
                  mdi-filter-variant
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-list>
                <v-list-item>
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <v-autocomplete
                      multiple
                      dense
                      auto-select-first
                      clearable
                      chips
                      small-chips
                      color="primary"
                      :items="columnValueList(header.value)"
                      item-value="id"
                      :item-text="filters[header.value]"
                      append-icon="mdi-filter"
                      v-model="filters[header.value]"
                      :label="
                        filters[header.value] ? `filter_by: ${header.text}` : ''
                      "
                      hide-details>
                      <template v-slot:selection="{ item, index }">
                        <v-chip small class="caption" v-if="index < 5">
                          <span>
                            {{ item }}
                          </span>
                        </v-chip>
                        <span v-if="index === 5" class="grey--text caption">
                          (+{{ filters[header.value].length - 5 }} others)
                        </span>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </template>

        <!-- Prodotto  -->
        <template v-slot:[`item.nome`]="props">
          <v-text-field
            @input="updateProdotto(props.item)"
            v-model.trim="props.item.nome"
            dense
            solo
            placeholder="nome prodotto">
          </v-text-field>
        </template>

        <template v-slot:[`item.id`]="props">
          {{ props.item.id }}
        </template>
        <template
          v-slot:[`item.mandato.percentuale_provvigione_attiva`]="props">
          <text-formatted
            @input="updateProdotto(props.item)"
            v-model="props.item.mandato.percentuale_provvigione_attiva"
            dense
            solo
            :disabled="!props.item.mandato.attivo"
            :error-messages="[]"
            suffix="%">
          </text-formatted>
        </template>

        <template v-slot:[`item.mandato.data_inizio_mandato`]="props">
          <date-picker
            data-cy="data_inizio_mandato"
            v-model="props.item.mandato.data_inizio_mandato"
            value-type="YYYY-MM-DD"
            @change="updateProdotto(props.item)"
            :disabled="!props.item.mandato.attivo"
            format="DD/MM/YYYY">
          </date-picker>
        </template>

        <!-- SETTORE -->

        <template v-slot:[`item.settore_id`]="props">
          <teo-autocomplete
            @input="updateProdotto(props.item)"
            v-model="props.item.settore_id"
            item-value="id"
            item-text="label"
            dense
            color="primary"
            resource="/api/autocomplete/settore" />
        </template>

        <template v-slot:[`item.mandato.attivo`]="props">
          <v-checkbox
            @change="updateProdotto(props.item)"
            dense
            solo
            v-model="props.item.mandato.attivo">
          </v-checkbox>
        </template>
        <template v-slot:[`item.ramo_id`]="props">
          <!-- {{ props.item.ramo_id }} -->

          <teo-autocomplete
            @input="updateProdotto(props.item)"
            v-model="props.item.ramo_id"
            item-value="id"
            item-text="label"
            dense
            color="primary"
            resource="/api/autocomplete/ramo" />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        Aggiungi Garanzie a questo prodotto {{ item.nome }}
      </td>
    </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="deleteCategoriaProdottoRow(item)">
            mdi-delete
          </v-icon>
        </template>

        
      </v-data-table>
    </section>
    loading {{ loading }}
    <section
      class="my-3 p-3"
      v-if="!(categoria_prodotto.length > 0) && !loading">
      <v-alert prominent icon="mdi-arrow-up" type="info">
        <v-row class="p-0">
          <v-col class="grow">
            <div
              v-if="new_categoria_prodotto.compagnia_contrattuale_id == null">
              <h4>Seleziona una compagnia contrattuale</h4>
              <p>Per vedere i prodotti assegnati</p>
            </div>
            <div
              v-if="new_categoria_prodotto.compagnia_contrattuale_id != null">
              <h4>
                Vuoi assegnare i prodotti di (COMPAGNIA_CONTRATTUALE) ? ({{
                  new_categoria_prodotto.compagnia_contrattuale_id
                }})
              </h4>
              <!-- <v-btn color="primary">Assegna i prodotto di (COMPAGNIA_CONTRATTUALE) {{ new_categoria_prodotto.compagnia_contrattuale_id }} a (FORNITORE)</v-btn> -->
            </div>
          </v-col>

          <!-- <v-col class="grow">
              Nessun mandato per questo fornitore. Seleziona una compagnia
              contrattuale o creane una nuova
              <pre>
                {{ new_categoria_prodotto }}
              </pre>
          </v-col> -->
        </v-row>
      </v-alert>
    </section>
    {{ expanded }}
  </section>
</template>

<script>
  import DatePicker from "../ui/form/teo-date-picker.vue";
  import Autocomplete from "../ui/Autocomplete.vue";
  import TextFormatted from "../ui/form/text-formatted.vue";
  import CompagniaContrattualeForm from "../compagnia_contrattuale/CompagniaContrattualeForm.vue";
  import { mapFields } from "vuex-map-fields";
  import { mapActions, mapState, mapGetters } from "vuex";
  import _ from "lodash";
   /* eslint-disable */ 
  export default {
    data(){
      return {
        expanded:[],
        singleExpand:true
      }
    },
    mounted() {
      // TODO inserire compagnia contrattuale globale
      this.$store.dispatch(
        "CompagniaContrattualeModule/action_compagnia_contrattuale_autocomplete"
      );
      // se il fornitore è definito

      // if(this.$route.params.id){
      //   this.$store.dispatch(
      //   "MandatoProdottoModule/ACTION_SEARCH_MANDATO_PRODOTTO",
      //   {
      //     compagnia_fornitore_id: this.$route.params.id,
      //     prodotto:{
      //       compagnia_contrattuale_id: null
      //     }
      //   }
      // );
      // }
      // this.$store.MandatoProdottoModule.state.categoria_prodotto = []
      this.$store.dispatch("SettoreModule/action_autocomplete_settore");
      this.$store.commit(
        "MandatoProdottoModule/setResetTabellaProdotti",
        this.$route.params.id
      );
    },
    components: {
      "date-picker": DatePicker,
      "teo-autocomplete": Autocomplete,
      TextFormatted,
      CompagniaContrattualeForm,
    },
    computed: {
      // # CompagniaContrattualeModule
      ...mapFields("CompagniaContrattualeModule", [
        "new_compagnia_contrattuale",
        "dialog_aggiungi_nuova_compagnia_contrattuale",
        "loading_aggiungi_nuova_compagnia_contrattuale",
      ]),

      ...mapState("CompagniaContrattualeModule", [
        "errors",
        "loading",
        "headers",
        "categoria_prodotto",
        "filters",
        "compagnia_contrattuale_autocomplete",
      ]),

      // # MandatoProdottoModule
      ...mapFields("MandatoProdottoModule", [
        "new_categoria_prodotto",
        "dialog_delete_mandato_prodotto",
      ]),

      ...mapState("MandatoProdottoModule", [
        "errors",
        "loading",
        "headers",
        "categoria_prodotto",
        "filters",
      ]),

      ...mapGetters({
        categoria_prodotto_filtred:
          "MandatoProdottoModule/categoria_prodotto_filtred",
      }),

      // # SettoreModule
      ...mapState("SettoreModule", {
        autocomplete_settore: "autocomplete_settore",
      }),
    },
    methods: {
      ...mapActions("CompagniaContrattualeModule", {
        storeNewCompagniaContrattuale: "store",
        resetNewCompagniaContrattuale: "reset",
      }),
      ...mapActions("MandatoProdottoModule", {
        deleteCategoriaProdottoRow: "deleteCategoriaProdottoRow",
        action_categoria_prodotto_row_update:
          "action_categoria_prodotto_row_update",
      }),
      addNewCategoriaProdottoRow() {
        this.new_categoria_prodotto.mandato.compagnia_fornitore_id =
          this.$route.params.id;

        this.$store.dispatch(
          "MandatoProdottoModule/action_add_new_categoria_prodotto_row",
          this.new_categoria_prodotto
          // TODO    this.$route.params.id || payload_new_categoria_prodotto.compagnia_fornitore_id
        );
      },
      newCategoriaProdottoChange() {
        console.log("newCategoriaProdottoChange", this.new_categoria_prodotto);

        this.new_categoria_prodotto.nome = "";

        this.new_categoria_prodotto.mandato.percentuale_provvigione_attiva = "";
        this.new_categoria_prodotto.mandato.data_inizio_mandato = "";
        this.new_categoria_prodotto.mandato.compagnia_fornitore_id =
          this.$route.params.id;

        this.ramo_id = null;
        this.settore_id = null;

        const query = {
          compagnia_fornitore_id: this.$route.params.id,
          prodotto: {
            compagnia_contrattuale_id:
              this.new_categoria_prodotto.compagnia_contrattuale_id,
          },
        };

        this.$store.dispatch(
          "MandatoProdottoModule/ACTION_SEARCH_MANDATO_PRODOTTO",
          query
        );
      },

      // updateProdotto(item) {
      //   this.$store.dispatch(
      //     "MandatoProdottoModule/action_categoria_prodotto_row_update",
      //     item
      //   );
      // },
      updateProdotto: _.debounce(function (item) {
        this.$store.dispatch(
          "MandatoProdottoModule/action_categoria_prodotto_row_update",
          item
        );
      }, 1000),

      /**
       * // IMPORTANTE // TODO: implementare filtri in tempo reale
       * @param {*} a
       * @param {*} b
       */
      comparatoreValori(a, b) {
        return String(a) == String(b);
      },
      columnValueList(val) {
        return this.categoria_prodotto.map((d) => d[val]);
      },
    },
  };
</script>

<style scoped>
  .prodotto_assicurativo__tabs_items {
  }

  .categoria_prodotto__add_new_form .row {
    display: flex;
    align-items: flex-end;
  }
  td .v-text-field__slot {
    margin-top: 8px;
  }

  .tr-fade-enter-active,
  .tr-fade-leave-active {
    transition: all 0.5s;
  }
  .tr-fade-enter, .tr-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
