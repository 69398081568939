import Vue from "vue";
import Vuex from "vuex";

import state  from "./state";
import * as getters  from "./getters";
import * as mutations  from "./mutations";
import * as actions from "./actions";



import MandatoProdottoModule from './modules/MandatoProdottoModule'
import SettoreModule from './modules/SettoreModule'
import PolizzaModule from './modules/PolizzaModule'
import CollaboratoreModule from './modules/CollaboratoreModule'
import ClienteModule from './modules/ClienteModule'
import CompagniaContrattualeModule from './modules/CompagniaContrattualeModule'

Vue.use(Vuex);
// console.log(state)
export  const store = new Vuex.Store({
    namespaced:true,
    state,
    mutations,
    actions,
    getters,
    modules:{ 
        MandatoProdottoModule,
        PolizzaModule,
        SettoreModule,
        CollaboratoreModule,
        ClienteModule,
        CompagniaContrattualeModule
    }

})